/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { MoisGen } from './mois.gen.model';
import { PeriodePaiementGen } from './periode-paiement.gen.model';


export interface PeriodiciteGen { 
    moisDebutPaiement?: MoisGen;
    nouvellePeriode?: PeriodePaiementGen;
}

export class PeriodiciteGen_ {
  static readonly moisDebutPaiement = 'moisDebutPaiement';
  readonly moisDebutPaiement = 'moisDebutPaiement';
  static readonly nouvellePeriode = 'nouvellePeriode';
  readonly nouvellePeriode = 'nouvellePeriode';
  static fields() {
    return new PeriodiciteGen_();
  }
}

