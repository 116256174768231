import {Component, Input, OnInit, ViewChild} from '@angular/core'
import {faTimes} from '@fortawesome/free-solid-svg-icons'
import {FormBuilder, FormGroup} from "@angular/forms"
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap"
import {AsCoreFormControl} from "../../shared/ascore/component/ascore-input/AsCoreFormControl"
import {NgSignaturePadOptions, SignaturePadComponent} from "@almothafar/angular-signature-pad"

//REFERENCE : https://www.npmjs.com/package/@almothafar/angular-signature-pad?activeTab=readme

@Component({
  selector: 'app-signature-modal',
  templateUrl: './signature-modal.component.html',
  styleUrls: ['./signature-modal.component.scss']
})
export class SignatureModalComponent implements OnInit {
  @ViewChild('signature')
  public signaturePad: SignaturePadComponent

  faTimes = faTimes

  @Input()
  qualiteJpeg = 0.5

  @Input()
  labelOk = 'Ok'

  @Input()
  labelAnnuler = 'Annuler'

  @Input()
  titre = 'Signature'

  @Input()
  btnClass: typeBtn = 'btn-primary'

  form: FormGroup

  signaturePadOptions: NgSignaturePadOptions = {
    canvasWidth: 500,
    canvasHeight: 300,
    backgroundColor: "rgb(255,255,255)"
  }

  constructor(public modal: NgbActiveModal,
              private fb: FormBuilder) {
  }

  ngOnInit(): void {
    this.form = this.fb.group({
      signatureJpegData: AsCoreFormControl.init().required().noLabel()
    })
  }

  drawComplete(event: MouseEvent | Touch) {
    this.form.get('signatureJpegData').setValue(this.signaturePad.toDataURL("image/jpeg", this.qualiteJpeg))
  }

}

export type typeBtn = 'btn-danger' | 'btn-primary'
