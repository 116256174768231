/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface SrItemAvenantPojoGen { 
    articleId?: number;
    articleInstanceLabel?: string;
    avenantId?: number;
    avenantInstanceLabel?: string;
    created?: Date;
    createdBy?: string;
    id?: number;
    instanceLabel?: string;
    numero?: string;
    prixUnitaire?: number;
    quantite?: number;
    remise?: number;
    updated?: Date;
    updatedBy?: string;
    versionNum?: number;
}

export class SrItemAvenantPojoGen_ {
  static readonly articleId = 'articleId';
  readonly articleId = 'articleId';
  static readonly articleInstanceLabel = 'articleInstanceLabel';
  readonly articleInstanceLabel = 'articleInstanceLabel';
  static readonly avenantId = 'avenantId';
  readonly avenantId = 'avenantId';
  static readonly avenantInstanceLabel = 'avenantInstanceLabel';
  readonly avenantInstanceLabel = 'avenantInstanceLabel';
  static readonly created = 'created';
  readonly created = 'created';
  static readonly createdBy = 'createdBy';
  readonly createdBy = 'createdBy';
  static readonly id = 'id';
  readonly id = 'id';
  static readonly instanceLabel = 'instanceLabel';
  readonly instanceLabel = 'instanceLabel';
  static readonly numero = 'numero';
  readonly numero = 'numero';
  static readonly prixUnitaire = 'prixUnitaire';
  readonly prixUnitaire = 'prixUnitaire';
  static readonly quantite = 'quantite';
  readonly quantite = 'quantite';
  static readonly remise = 'remise';
  readonly remise = 'remise';
  static readonly updated = 'updated';
  readonly updated = 'updated';
  static readonly updatedBy = 'updatedBy';
  readonly updatedBy = 'updatedBy';
  static readonly versionNum = 'versionNum';
  readonly versionNum = 'versionNum';
  static fields() {
    return new SrItemAvenantPojoGen_();
  }
}

