import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {AsCoreTab} from '../../../../shared/ascore/component/ascore-tab/ascore-tab.model';
import {AsCoreFormControl} from '../../../../shared/ascore/component/ascore-input/AsCoreFormControl';
import {UtilisateurGen_, UtilisateurServiceGen} from '../../../../shared/generated';
import {faTimes} from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-utilisateur-create-detail',
  templateUrl: './utilisateur-create-detail.component.html',
  styleUrls: ['./utilisateur-create-detail.component.scss']
})
export class UtilisateurCreateDetailComponent implements OnInit {

  faTimes = faTimes;

  public form: FormGroup;


  tabs: AsCoreTab[] = [];

  creation = false;

  fields = UtilisateurGen_.fields();

  constructor(private fb: FormBuilder,
              public service: UtilisateurServiceGen) {
  }


  ngOnInit(): void {
    this.createForm();
  }

  createForm(): void {
    this.form = this.fb.group({
      [UtilisateurGen_.username]: AsCoreFormControl.init().required().label('Identifiant'),
      [UtilisateurGen_.actif]: AsCoreFormControl.init(true).required().label('Actif'),
      [UtilisateurGen_.password]: AsCoreFormControl.init().required().label('Mot de passe'),
    });
  }
}
