<ascore-detail
        [form]="form"
        domainName="Utilisateur"
        [withCrud]="utilisateurService"
        [title]="'Mise à jour de mes informations personnelles'"
        [entity]="utilisateur"
>

    <form [formGroup]="form" autocomplete="off">

        <div class="row">

            <div class="col-md-4">
                <input type="text"
                       formControlName="email">
                <input type="text"
                       asCoreUppercase
                       formControlName="tel1"
                >
                <input type="text"
                       asCoreUppercase
                       formControlName="tel2"
                >
            </div>

            <div class="col-md-4">
                <div style="height: 1em"></div>
                <app-alert type="info">
                    Pour modifier vos autres informations (fonction, rôles...) vous devez contacter un administrateur.
                </app-alert>
            </div>

        </div>

    </form>
</ascore-detail>
