import {Directive, ElementRef, Input, OnDestroy, OnInit} from '@angular/core';
import {fromEvent, Subscription} from 'rxjs';

@Directive({
  selector: 'button:not([autoriseMultipleClick]), a:not([autoriseMultipleClick])'
})
export class PreventMultipleClickDirective implements OnInit, OnDestroy {
  subscription = new Subscription();

  clickEnCours = false;

  @Input()
  durationDisable = 300;

  constructor(private elRef: ElementRef) {
  }

  ngOnInit(): void {
    const el = this.elRef.nativeElement;
    this.subscription = fromEvent(el, 'click', {capture: true})
      .subscribe((e: any) => {
        if (this.clickEnCours) {
          el.setAttribute('disabled', 'true');
          e.preventDefault();
          e.stopPropagation();
        }
        this.clickEnCours = true;
        setTimeout(() => {
          el.removeAttribute('disabled');
          this.clickEnCours = false;
        }, this.durationDisable);
      });
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
