import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {SharedModule} from '../../../shared/shared.module';
import {AsCoreModule} from '../../../shared/ascore/ascore.module';
import {UtilisateurRoutingModule} from './utilisateur.routing';
import {UtilisateurSearchComponent} from './utilisateur-search/utilisateur-search.component';
import {UtilisateurDetailComponent} from './utilisateur-detail/utilisateur-detail.component';

import {UtilisateurCreateDetailComponent} from './utilisateur-create-detail/utilisateur-create-detail.component';

@NgModule({
  imports: [
    SharedModule,
    CommonModule,
    UtilisateurRoutingModule,
    AsCoreModule
  ],
  declarations: [UtilisateurSearchComponent,
    UtilisateurDetailComponent,

    UtilisateurCreateDetailComponent,],
  providers: []
})
export class UtilisateurModule {
}
