/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface SrFacturePojoGen { 
    clientId?: number;
    clientInstanceLabel?: string;
    created?: Date;
    createdBy?: string;
    dateEmission?: Date;
    dateFacturation?: Date;
    etatFactureId?: string;
    etatFactureInstanceLabel?: string;
    exporteQuadratus?: boolean;
    id?: number;
    instanceLabel?: string;
    listContrat?: string;
    listContratId?: string;
    listReferenceClient?: string;
    modePaiementId?: string;
    modePaiementInstanceLabel?: string;
    numero?: string;
    numeroFactureAvoir?: string;
    reference?: string;
    sansAdresseMail?: boolean;
    totalHt?: number;
    totalTgc?: number;
    totalTtc?: number;
    transmisCourrier?: boolean;
    transmisEmail?: boolean;
    updated?: Date;
    updatedBy?: string;
    versionNum?: number;
}

export class SrFacturePojoGen_ {
  static readonly clientId = 'clientId';
  readonly clientId = 'clientId';
  static readonly clientInstanceLabel = 'clientInstanceLabel';
  readonly clientInstanceLabel = 'clientInstanceLabel';
  static readonly created = 'created';
  readonly created = 'created';
  static readonly createdBy = 'createdBy';
  readonly createdBy = 'createdBy';
  static readonly dateEmission = 'dateEmission';
  readonly dateEmission = 'dateEmission';
  static readonly dateFacturation = 'dateFacturation';
  readonly dateFacturation = 'dateFacturation';
  static readonly etatFactureId = 'etatFactureId';
  readonly etatFactureId = 'etatFactureId';
  static readonly etatFactureInstanceLabel = 'etatFactureInstanceLabel';
  readonly etatFactureInstanceLabel = 'etatFactureInstanceLabel';
  static readonly exporteQuadratus = 'exporteQuadratus';
  readonly exporteQuadratus = 'exporteQuadratus';
  static readonly id = 'id';
  readonly id = 'id';
  static readonly instanceLabel = 'instanceLabel';
  readonly instanceLabel = 'instanceLabel';
  static readonly listContrat = 'listContrat';
  readonly listContrat = 'listContrat';
  static readonly listContratId = 'listContratId';
  readonly listContratId = 'listContratId';
  static readonly listReferenceClient = 'listReferenceClient';
  readonly listReferenceClient = 'listReferenceClient';
  static readonly modePaiementId = 'modePaiementId';
  readonly modePaiementId = 'modePaiementId';
  static readonly modePaiementInstanceLabel = 'modePaiementInstanceLabel';
  readonly modePaiementInstanceLabel = 'modePaiementInstanceLabel';
  static readonly numero = 'numero';
  readonly numero = 'numero';
  static readonly numeroFactureAvoir = 'numeroFactureAvoir';
  readonly numeroFactureAvoir = 'numeroFactureAvoir';
  static readonly reference = 'reference';
  readonly reference = 'reference';
  static readonly sansAdresseMail = 'sansAdresseMail';
  readonly sansAdresseMail = 'sansAdresseMail';
  static readonly totalHt = 'totalHt';
  readonly totalHt = 'totalHt';
  static readonly totalTgc = 'totalTgc';
  readonly totalTgc = 'totalTgc';
  static readonly totalTtc = 'totalTtc';
  readonly totalTtc = 'totalTtc';
  static readonly transmisCourrier = 'transmisCourrier';
  readonly transmisCourrier = 'transmisCourrier';
  static readonly transmisEmail = 'transmisEmail';
  readonly transmisEmail = 'transmisEmail';
  static readonly updated = 'updated';
  readonly updated = 'updated';
  static readonly updatedBy = 'updatedBy';
  readonly updatedBy = 'updatedBy';
  static readonly versionNum = 'versionNum';
  readonly versionNum = 'versionNum';
  static fields() {
    return new SrFacturePojoGen_();
  }
}

