<perfect-scrollbar *ngIf="this.flatConfig['layout'] === 'vertical'" (clickOutside)="navMob()"
                   [excludeBeforeClick]="true" [exclude]="'#mobile-collapse'" id="nav-ps-flat-able"
                   ngClass="flat-able-scroll" [style.max-width]="'300px'" [style.max-height]="'calc(100vh - 56px)'"
                   [usePSClass]=true [disabled]="null">
  <div class="navbar-content">
    <ul class="nav pcoded-inner-navbar" (clickOutside)="fireOutClick()">
      <ng-container *ngFor="let item of navigation">
        <app-nav-group *ngIf="item.type=='group'" [item]="item"></app-nav-group>
        <app-nav-collapse *ngIf="item.type=='collapse'" [item]="item"></app-nav-collapse>
        <app-nav-item *ngIf="item.type=='item'" [item]="item"></app-nav-item>
      </ng-container>
    </ul>
    <hr>

    <div *ngIf="window.innerWidth < 992" style="text-align: center">
      <h4 style="padding-top: 14px">
        <i class="fas fa-sign-out-alt"
           (click)="logout()"
           style="cursor: pointer;"
           ngbTooltip="Se déconnecter"></i></h4>
    </div>

    <div style="height: 5rem"></div>
  </div>
</perfect-scrollbar>

<div *ngIf="this.flatConfig['layout'] === 'horizontal'" #navbarWrapper class="navbar-content sidenav-horizontal"
     id="layout-sidenav">
  <a href="javascript:" class="sidenav-horizontal-prev" [ngClass]="prevDisabled" (click)="scrollMinus()"></a>
  <div class="sidenav-horizontal-wrapper">
    <ul #navbarContent id="side-nav-horizontal" class="nav pcoded-inner-navbar sidenav-inner"
        (clickOutside)="fireLeave()" (mouseleave)="fireLeave()">
      <ng-container *ngFor="let item of navigation">
        <app-nav-group *ngIf="item.type=='group'" [item]="item"></app-nav-group>
        <app-nav-collapse *ngIf="item.type=='collapse'" [item]="item"></app-nav-collapse>
        <app-nav-item *ngIf="item.type=='item'" [item]="item"></app-nav-item>
      </ng-container>
    </ul>
  </div>
  <a href="javascript:" class="sidenav-horizontal-next" [ngClass]="nextDisabled" (click)="scrollPlus()"></a>
</div>
