/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { UtilisateurGen } from './utilisateur.gen.model';
import { DevisLocationGen } from './devis-location.gen.model';


export interface RelanceDevisGen { 
    commentaire?: string;
    conseiller: UtilisateurGen;
    created: Date;
    createdBy: string;
    dateRelance: Date;
    devis: DevisLocationGen;
    dtype?: string;
    id?: number;
    instanceLabel: string;
    updated?: Date;
    updatedBy?: string;
    versionNum?: number;
}

export class RelanceDevisGen_ {
  static readonly commentaire = 'commentaire';
  readonly commentaire = 'commentaire';
  static readonly conseiller = 'conseiller';
  readonly conseiller = 'conseiller';
  static readonly created = 'created';
  readonly created = 'created';
  static readonly createdBy = 'createdBy';
  readonly createdBy = 'createdBy';
  static readonly dateRelance = 'dateRelance';
  readonly dateRelance = 'dateRelance';
  static readonly devis = 'devis';
  readonly devis = 'devis';
  static readonly dtype = 'dtype';
  readonly dtype = 'dtype';
  static readonly id = 'id';
  readonly id = 'id';
  static readonly instanceLabel = 'instanceLabel';
  readonly instanceLabel = 'instanceLabel';
  static readonly updated = 'updated';
  readonly updated = 'updated';
  static readonly updatedBy = 'updatedBy';
  readonly updatedBy = 'updatedBy';
  static readonly versionNum = 'versionNum';
  readonly versionNum = 'versionNum';
  static fields() {
    return new RelanceDevisGen_();
  }
}

