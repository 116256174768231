/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type CiviliteGen = 'M' | 'MME';

export const CiviliteGen = {
        M: 'M' as CiviliteGen,
        MME: 'MME' as CiviliteGen
        };

export class CiviliteGen_ {
    static readonly instanceLabel = 'instanceLabel';
    readonly instanceLabel = 'instanceLabel';
static fields() {
return new CiviliteGen_();
}
}

