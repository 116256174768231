/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type EtatFactureGen = 'EMISE' | 'ACQUITTEE' | 'ERREUR';

export const EtatFactureGen = {
        EMISE: 'EMISE' as EtatFactureGen,
        ACQUITTEE: 'ACQUITTEE' as EtatFactureGen,
        ERREUR: 'ERREUR' as EtatFactureGen
        };

export class EtatFactureGen_ {
    static readonly instanceLabel = 'instanceLabel';
    readonly instanceLabel = 'instanceLabel';
static fields() {
return new EtatFactureGen_();
}
}

