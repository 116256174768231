/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ClientGen } from './client.gen.model';
import { SiteGen } from './site.gen.model';


export interface ActiviteClientGen { 
    appel?: number;
    client?: ClientGen;
    commentaire?: string;
    created: Date;
    createdBy: string;
    dtype?: string;
    entree?: number;
    id?: number;
    instanceLabel: string;
    mail?: number;
    reglement?: number;
    site?: SiteGen;
    sortie?: number;
    source?: string;
    updated?: Date;
    updatedBy?: string;
    versionNum?: number;
    visite?: number;
    web?: number;
}

export class ActiviteClientGen_ {
  static readonly appel = 'appel';
  readonly appel = 'appel';
  static readonly client = 'client';
  readonly client = 'client';
  static readonly commentaire = 'commentaire';
  readonly commentaire = 'commentaire';
  static readonly created = 'created';
  readonly created = 'created';
  static readonly createdBy = 'createdBy';
  readonly createdBy = 'createdBy';
  static readonly dtype = 'dtype';
  readonly dtype = 'dtype';
  static readonly entree = 'entree';
  readonly entree = 'entree';
  static readonly id = 'id';
  readonly id = 'id';
  static readonly instanceLabel = 'instanceLabel';
  readonly instanceLabel = 'instanceLabel';
  static readonly mail = 'mail';
  readonly mail = 'mail';
  static readonly reglement = 'reglement';
  readonly reglement = 'reglement';
  static readonly site = 'site';
  readonly site = 'site';
  static readonly sortie = 'sortie';
  readonly sortie = 'sortie';
  static readonly source = 'source';
  readonly source = 'source';
  static readonly updated = 'updated';
  readonly updated = 'updated';
  static readonly updatedBy = 'updatedBy';
  readonly updatedBy = 'updatedBy';
  static readonly versionNum = 'versionNum';
  readonly versionNum = 'versionNum';
  static readonly visite = 'visite';
  readonly visite = 'visite';
  static readonly web = 'web';
  readonly web = 'web';
  static fields() {
    return new ActiviteClientGen_();
  }
}

