/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type TypeDocumentGen = 'CONTRAT' | 'AVENANT' | 'COMPLEMENT' | 'RECOUVREMENT';

export const TypeDocumentGen = {
        CONTRAT: 'CONTRAT' as TypeDocumentGen,
        AVENANT: 'AVENANT' as TypeDocumentGen,
        COMPLEMENT: 'COMPLEMENT' as TypeDocumentGen,
        RECOUVREMENT: 'RECOUVREMENT' as TypeDocumentGen
        };

export class TypeDocumentGen_ {
    static readonly instanceLabel = 'instanceLabel';
    readonly instanceLabel = 'instanceLabel';
static fields() {
return new TypeDocumentGen_();
}
}

