import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {ActivatedRoute} from '@angular/router';
import {AsCoreTab} from '../../../../shared/ascore/component/ascore-tab/ascore-tab.model';
import {AsCoreFormControl} from '../../../../shared/ascore/component/ascore-input/AsCoreFormControl';
import {RoleGen_, RoleServiceGen, UtilisateurGen_, UtilisateurServiceGen} from '../../../../shared/generated';
import {faTimes} from '@fortawesome/free-solid-svg-icons';
import {AuditUtil} from '../../../../shared/ascore/component/ascore-audit/audit-util';
import {AsCoreColumn} from '../../../../shared/ascore/component/ascore-search/ascore-search.component';

@Component({
  selector: 'app-utilisateur-detail',
  templateUrl: './utilisateur-detail.component.html',
  styleUrls: ['./utilisateur-detail.component.scss']
})
export class UtilisateurDetailComponent implements OnInit {

  faTimes = faTimes;

  public form: FormGroup;

  tabRoleColumns: AsCoreColumn[] = [
    {header: 'Libellé', fieldName: RoleGen_.instanceLabel, sortFieldName: RoleGen_.instanceLabel},
  ];


  tabs: AsCoreTab[] = [];

  creation = false;

  fields = UtilisateurGen_.fields();

  constructor(private fb: FormBuilder,
              private route: ActivatedRoute,
              public roleService: RoleServiceGen,
              public service: UtilisateurServiceGen) {
  }

  getTabs(): AsCoreTab[] {
    const tabs: AsCoreTab[] = [
      {
        titre: 'Rôles',
        type: 'lien',
        withSearch: this.roleService,
        columns: this.tabRoleColumns,
        formControlName: UtilisateurGen_.listRole,
        searchFieldName: 'recherche',
      },
    ];

    if (!this.creation) {
      tabs.push(AuditUtil.tabAudit(this.form.get(UtilisateurGen_.id).value, this.service));
    }

    return tabs;
  }

  ngOnInit(): void {
    this.creation = this.route.snapshot.routeConfig.path === 'creation';
    this.createForm();
    this.tabs = this.getTabs();
  }

  createForm(): void {
    this.form = this.fb.group({
      [UtilisateurGen_.username]: AsCoreFormControl.init().required().label('Identifiant'),
      [UtilisateurGen_.password]: AsCoreFormControl.init().label('Mot de passe'),
      [UtilisateurGen_.nom]: AsCoreFormControl.init().label('Nom'),
      [UtilisateurGen_.prenom]: AsCoreFormControl.init().label('Prénom'),
      [UtilisateurGen_.fonction]: AsCoreFormControl.init().label('Fonction'),
      [UtilisateurGen_.email]: AsCoreFormControl.init().label('Email'),
      [UtilisateurGen_.actif]: AsCoreFormControl.init().required().label('Actif'),
      [UtilisateurGen_.listRole]: AsCoreFormControl.init().label('Rôles'),
      [UtilisateurGen_.id]: AsCoreFormControl.init().noLabel(),
      [UtilisateurGen_.versionNum]: AsCoreFormControl.init().noLabel(),
    });
  }
}
