/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ItemContratGen } from './item-contrat.gen.model';
import { UtilisateurGen } from './utilisateur.gen.model';
import { MoisGen } from './mois.gen.model';
import { ClientGen } from './client.gen.model';
import { DevisLocationGen } from './devis-location.gen.model';
import { PeriodePaiementGen } from './periode-paiement.gen.model';
import { AvenantGen } from './avenant.gen.model';
import { DocumentGen } from './document.gen.model';
import { EtatContratGen } from './etat-contrat.gen.model';


export interface ContratLocationGen { 
    client: ClientGen;
    conditionsParticulieres?: string;
    conseiller: UtilisateurGen;
    created: Date;
    createdBy: string;
    dateEmission: Date;
    dateEntree: Date;
    dateResiliation?: Date;
    devisLocation?: DevisLocationGen;
    dtype?: string;
    etat: EtatContratGen;
    exonereTgc?: boolean;
    id?: number;
    instanceLabel: string;
    listAvenant?: Set<AvenantGen>;
    listDocument?: Set<DocumentGen>;
    listItemContrat?: Set<ItemContratGen>;
    listNumBadges?: string;
    moisDebutPaiementHorsMensuel?: MoisGen;
    nbBadge?: number;
    nbCadenas?: number;
    nbCle?: number;
    nbreMonthsPeriodePaiement?: number;
    numero: string;
    periodePaiement?: PeriodePaiementGen;
    premierMoisPayeAvance?: boolean;
    prixPublicHt?: number;
    prixRemiseHt?: number;
    qualiteRepresentant?: string;
    referenceClient?: string;
    remiseMoyenne?: number;
    representePar?: string;
    transmis?: boolean;
    updated?: Date;
    updatedBy?: string;
    versionNum?: number;
}

export class ContratLocationGen_ {
  static readonly client = 'client';
  readonly client = 'client';
  static readonly conditionsParticulieres = 'conditionsParticulieres';
  readonly conditionsParticulieres = 'conditionsParticulieres';
  static readonly conseiller = 'conseiller';
  readonly conseiller = 'conseiller';
  static readonly created = 'created';
  readonly created = 'created';
  static readonly createdBy = 'createdBy';
  readonly createdBy = 'createdBy';
  static readonly dateEmission = 'dateEmission';
  readonly dateEmission = 'dateEmission';
  static readonly dateEntree = 'dateEntree';
  readonly dateEntree = 'dateEntree';
  static readonly dateResiliation = 'dateResiliation';
  readonly dateResiliation = 'dateResiliation';
  static readonly devisLocation = 'devisLocation';
  readonly devisLocation = 'devisLocation';
  static readonly dtype = 'dtype';
  readonly dtype = 'dtype';
  static readonly etat = 'etat';
  readonly etat = 'etat';
  static readonly exonereTgc = 'exonereTgc';
  readonly exonereTgc = 'exonereTgc';
  static readonly id = 'id';
  readonly id = 'id';
  static readonly instanceLabel = 'instanceLabel';
  readonly instanceLabel = 'instanceLabel';
  static readonly listAvenant = 'listAvenant';
  readonly listAvenant = 'listAvenant';
  static readonly listDocument = 'listDocument';
  readonly listDocument = 'listDocument';
  static readonly listItemContrat = 'listItemContrat';
  readonly listItemContrat = 'listItemContrat';
  static readonly listNumBadges = 'listNumBadges';
  readonly listNumBadges = 'listNumBadges';
  static readonly moisDebutPaiementHorsMensuel = 'moisDebutPaiementHorsMensuel';
  readonly moisDebutPaiementHorsMensuel = 'moisDebutPaiementHorsMensuel';
  static readonly nbBadge = 'nbBadge';
  readonly nbBadge = 'nbBadge';
  static readonly nbCadenas = 'nbCadenas';
  readonly nbCadenas = 'nbCadenas';
  static readonly nbCle = 'nbCle';
  readonly nbCle = 'nbCle';
  static readonly nbreMonthsPeriodePaiement = 'nbreMonthsPeriodePaiement';
  readonly nbreMonthsPeriodePaiement = 'nbreMonthsPeriodePaiement';
  static readonly numero = 'numero';
  readonly numero = 'numero';
  static readonly periodePaiement = 'periodePaiement';
  readonly periodePaiement = 'periodePaiement';
  static readonly premierMoisPayeAvance = 'premierMoisPayeAvance';
  readonly premierMoisPayeAvance = 'premierMoisPayeAvance';
  static readonly prixPublicHt = 'prixPublicHt';
  readonly prixPublicHt = 'prixPublicHt';
  static readonly prixRemiseHt = 'prixRemiseHt';
  readonly prixRemiseHt = 'prixRemiseHt';
  static readonly qualiteRepresentant = 'qualiteRepresentant';
  readonly qualiteRepresentant = 'qualiteRepresentant';
  static readonly referenceClient = 'referenceClient';
  readonly referenceClient = 'referenceClient';
  static readonly remiseMoyenne = 'remiseMoyenne';
  readonly remiseMoyenne = 'remiseMoyenne';
  static readonly representePar = 'representePar';
  readonly representePar = 'representePar';
  static readonly transmis = 'transmis';
  readonly transmis = 'transmis';
  static readonly updated = 'updated';
  readonly updated = 'updated';
  static readonly updatedBy = 'updatedBy';
  readonly updatedBy = 'updatedBy';
  static readonly versionNum = 'versionNum';
  readonly versionNum = 'versionNum';
  static fields() {
    return new ContratLocationGen_();
  }
}

