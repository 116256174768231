/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type TauxTgcGen = 'T0' | 'T3' | 'T6' | 'T11' | 'T22';

export const TauxTgcGen = {
        T0: 'T0' as TauxTgcGen,
        T3: 'T3' as TauxTgcGen,
        T6: 'T6' as TauxTgcGen,
        T11: 'T11' as TauxTgcGen,
        T22: 'T22' as TauxTgcGen
        };

export class TauxTgcGen_ {
    static readonly instanceLabel = 'instanceLabel';
    readonly instanceLabel = 'instanceLabel';
static fields() {
return new TauxTgcGen_();
}
}

