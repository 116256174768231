/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { BoxGen } from './box.gen.model';
import { ClientGen } from './client.gen.model';
import { TypeAvenantGen } from './type-avenant.gen.model';
import { ItemAvenantGen } from './item-avenant.gen.model';
import { DocumentGen } from './document.gen.model';


export interface AvenantDtoGen { 
    ajouterBox?: boolean;
    ancienBox?: BoxGen;
    commentaire1?: string;
    commentaire2?: string;
    dateAvenant?: Date;
    listDocument?: Set<DocumentGen>;
    listItemAvenant?: Set<ItemAvenantGen>;
    nbBadgeRemis?: number;
    nbBadgeRendu?: number;
    nbreBadge?: string;
    nbreCadenas?: string;
    nbreCle?: string;
    nouveauBox?: BoxGen;
    nouveauClient?: ClientGen;
    numerosBadgeRemis?: string;
    numerosBadgeRendu?: string;
    prixHt?: number;
    prixRemiseHt?: number;
    remise?: number;
    typeAvenant?: TypeAvenantGen;
}

export class AvenantDtoGen_ {
  static readonly ajouterBox = 'ajouterBox';
  readonly ajouterBox = 'ajouterBox';
  static readonly ancienBox = 'ancienBox';
  readonly ancienBox = 'ancienBox';
  static readonly commentaire1 = 'commentaire1';
  readonly commentaire1 = 'commentaire1';
  static readonly commentaire2 = 'commentaire2';
  readonly commentaire2 = 'commentaire2';
  static readonly dateAvenant = 'dateAvenant';
  readonly dateAvenant = 'dateAvenant';
  static readonly listDocument = 'listDocument';
  readonly listDocument = 'listDocument';
  static readonly listItemAvenant = 'listItemAvenant';
  readonly listItemAvenant = 'listItemAvenant';
  static readonly nbBadgeRemis = 'nbBadgeRemis';
  readonly nbBadgeRemis = 'nbBadgeRemis';
  static readonly nbBadgeRendu = 'nbBadgeRendu';
  readonly nbBadgeRendu = 'nbBadgeRendu';
  static readonly nbreBadge = 'nbreBadge';
  readonly nbreBadge = 'nbreBadge';
  static readonly nbreCadenas = 'nbreCadenas';
  readonly nbreCadenas = 'nbreCadenas';
  static readonly nbreCle = 'nbreCle';
  readonly nbreCle = 'nbreCle';
  static readonly nouveauBox = 'nouveauBox';
  readonly nouveauBox = 'nouveauBox';
  static readonly nouveauClient = 'nouveauClient';
  readonly nouveauClient = 'nouveauClient';
  static readonly numerosBadgeRemis = 'numerosBadgeRemis';
  readonly numerosBadgeRemis = 'numerosBadgeRemis';
  static readonly numerosBadgeRendu = 'numerosBadgeRendu';
  readonly numerosBadgeRendu = 'numerosBadgeRendu';
  static readonly prixHt = 'prixHt';
  readonly prixHt = 'prixHt';
  static readonly prixRemiseHt = 'prixRemiseHt';
  readonly prixRemiseHt = 'prixRemiseHt';
  static readonly remise = 'remise';
  readonly remise = 'remise';
  static readonly typeAvenant = 'typeAvenant';
  readonly typeAvenant = 'typeAvenant';
  static fields() {
    return new AvenantDtoGen_();
  }
}

