import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { AsCoreFormControl } from '../../shared/ascore/component/ascore-input/AsCoreFormControl';
import { UtilisateurCourantService } from '../../administration/referentiel/utilisateur-courant.service';
import { UtilisateurGen, UtilisateurServiceGen } from '../../shared/generated';

@Component({
  selector: 'app-mon-compte',
  templateUrl: './mon-compte.component.html',
  styleUrls: ['./mon-compte.component.scss']
})
export class MonCompteComponent implements OnInit {

  form: FormGroup;
  utilisateur: UtilisateurGen;

  constructor(private fb: FormBuilder,
              public utilisateurService: UtilisateurServiceGen,
              public utilisateurCourantService: UtilisateurCourantService) {
  }

  ngOnInit(): void {
    this.utilisateur = this.utilisateurCourantService.getUtilisateurCourant()
    this.createForm();
  }

  createForm(): void {
    this.form = this.fb.group({
      id: AsCoreFormControl.init(),
      nom: AsCoreFormControl.init().label('Nom'),
      prenom: AsCoreFormControl.init().label('Prénom'),
      fonction: AsCoreFormControl.init().label('Fonction'),
      email: AsCoreFormControl.init().email().required().label('Email'),
      tel1: AsCoreFormControl.init().pattern('\\+?[\\d\\. -]*\\d').label('Téléphone 1'),
      tel2: AsCoreFormControl.init().pattern('\\+?[\\d\\. -]*\\d').label('Téléphone 2'),
      listRole: AsCoreFormControl.init([]).label('Rôles'),
      username: AsCoreFormControl.init().label('Idenfiant de connexion'),
      ancienUsername: AsCoreFormControl.init().label('Idenfiant de connexion'),
      ancienPassword: AsCoreFormControl.init().label('Ancien mot de passe'),
      nouveauPassword: AsCoreFormControl.init().label('Nouveau mot de passe'),
      password: AsCoreFormControl.init().label('Mot de passe'),
      actif: AsCoreFormControl.init().label('Actif')
    });
  }

  updatePassword() {
    this.utilisateurCourantService.verifieLogin({
      username: this.form.get('ancienUsername').value,
      password: this.form.get('ancienPassword').value
    }).subscribe(next => {
      if (next.ok) {
        this.form.get('password').setValue(this.form.get('nouveauPassword').value)
      }
    })
  }
}


