/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type EtatContratGen = 'CREE' | 'RESILIE' | 'SOLDE';

export const EtatContratGen = {
        CREE: 'CREE' as EtatContratGen,
        RESILIE: 'RESILIE' as EtatContratGen,
        SOLDE: 'SOLDE' as EtatContratGen
        };

export class EtatContratGen_ {
    static readonly instanceLabel = 'instanceLabel';
    readonly instanceLabel = 'instanceLabel';
static fields() {
return new EtatContratGen_();
}
}

