/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface SrRelanceDevisPojoGen { 
    clientId?: number;
    clientInstanceLabel?: string;
    commentaire?: string;
    conseillerInstanceLabel?: string;
    created?: Date;
    createdBy?: string;
    dateRelance?: Date;
    devisLocationEtat?: string;
    devisLocationEtatInstanceLabel?: string;
    devisLocationEtatNom?: string;
    devisLocationId?: number;
    devisLocationInstanceLabel?: string;
    id?: number;
    instanceLabel?: string;
    updated?: Date;
    updatedBy?: string;
    versionNum?: number;
}

export class SrRelanceDevisPojoGen_ {
  static readonly clientId = 'clientId';
  readonly clientId = 'clientId';
  static readonly clientInstanceLabel = 'clientInstanceLabel';
  readonly clientInstanceLabel = 'clientInstanceLabel';
  static readonly commentaire = 'commentaire';
  readonly commentaire = 'commentaire';
  static readonly conseillerInstanceLabel = 'conseillerInstanceLabel';
  readonly conseillerInstanceLabel = 'conseillerInstanceLabel';
  static readonly created = 'created';
  readonly created = 'created';
  static readonly createdBy = 'createdBy';
  readonly createdBy = 'createdBy';
  static readonly dateRelance = 'dateRelance';
  readonly dateRelance = 'dateRelance';
  static readonly devisLocationEtat = 'devisLocationEtat';
  readonly devisLocationEtat = 'devisLocationEtat';
  static readonly devisLocationEtatInstanceLabel = 'devisLocationEtatInstanceLabel';
  readonly devisLocationEtatInstanceLabel = 'devisLocationEtatInstanceLabel';
  static readonly devisLocationEtatNom = 'devisLocationEtatNom';
  readonly devisLocationEtatNom = 'devisLocationEtatNom';
  static readonly devisLocationId = 'devisLocationId';
  readonly devisLocationId = 'devisLocationId';
  static readonly devisLocationInstanceLabel = 'devisLocationInstanceLabel';
  readonly devisLocationInstanceLabel = 'devisLocationInstanceLabel';
  static readonly id = 'id';
  readonly id = 'id';
  static readonly instanceLabel = 'instanceLabel';
  readonly instanceLabel = 'instanceLabel';
  static readonly updated = 'updated';
  readonly updated = 'updated';
  static readonly updatedBy = 'updatedBy';
  readonly updatedBy = 'updatedBy';
  static readonly versionNum = 'versionNum';
  readonly versionNum = 'versionNum';
  static fields() {
    return new SrRelanceDevisPojoGen_();
  }
}

