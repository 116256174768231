<div class="modal-header">
    <h6 class="modal-title">{{titre}}</h6>
    <button type="button" class="close customClose" aria-label="Close button" aria-describedby="modal-title"
            (click)="modal.dismiss()">
        <fa-icon [icon]="faTimes" class="tw-text-white"></fa-icon>
    </button>
</div>
<div class="modal-body" [formGroup]="form">

    <app-alert *ngIf="erreur" type="danger">
        <div class="text-center">
            <i class="fa fa-exclamation-triangle"></i>
            {{erreur}}
        </div>
    </app-alert>

    <div *ngIf="!erreur">
        <form [formGroup]="form">
            <input type="text" formControlName="code">
        </form>

        <div class="text-center mt-4">
            <zxing-scanner #scanner [formats]="allowedFormats"
                           (scanSuccess)="scanSuccess($event)"
            ></zxing-scanner>
        </div>
    </div>
</div>

<div class="modal-footer">
    <button *ngIf="labelAnnuler" type="button" class="btn btn-outline-secondary"
            (click)="modal.dismiss()">{{labelAnnuler}}
    </button>
    <button type="button" class="btn" [class]="btnClass"
            #btnOK
            [disabled]="this.form.invalid"
            (click)="modal.close(this.getCode())">
        {{labelOk}}
    </button>
</div>
