/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { BoxGen } from './box.gen.model';
import { NiveauGen } from './niveau.gen.model';
import { SiteGen } from './site.gen.model';


export interface VueBoxGen { 
    anomalie?: boolean;
    box?: BoxGen;
    created: Date;
    createdBy: string;
    dtype?: string;
    hauteur?: number;
    id?: number;
    identifiant?: string;
    instanceLabel: string;
    largeur?: number;
    niveau?: NiveauGen;
    rotation?: number;
    site?: SiteGen;
    updated?: Date;
    updatedBy?: string;
    versionNum?: number;
    vueBoxLiee?: VueBoxGen;
    x?: number;
    y?: number;
}

export class VueBoxGen_ {
  static readonly anomalie = 'anomalie';
  readonly anomalie = 'anomalie';
  static readonly box = 'box';
  readonly box = 'box';
  static readonly created = 'created';
  readonly created = 'created';
  static readonly createdBy = 'createdBy';
  readonly createdBy = 'createdBy';
  static readonly dtype = 'dtype';
  readonly dtype = 'dtype';
  static readonly hauteur = 'hauteur';
  readonly hauteur = 'hauteur';
  static readonly id = 'id';
  readonly id = 'id';
  static readonly identifiant = 'identifiant';
  readonly identifiant = 'identifiant';
  static readonly instanceLabel = 'instanceLabel';
  readonly instanceLabel = 'instanceLabel';
  static readonly largeur = 'largeur';
  readonly largeur = 'largeur';
  static readonly niveau = 'niveau';
  readonly niveau = 'niveau';
  static readonly rotation = 'rotation';
  readonly rotation = 'rotation';
  static readonly site = 'site';
  readonly site = 'site';
  static readonly updated = 'updated';
  readonly updated = 'updated';
  static readonly updatedBy = 'updatedBy';
  readonly updatedBy = 'updatedBy';
  static readonly versionNum = 'versionNum';
  readonly versionNum = 'versionNum';
  static readonly vueBoxLiee = 'vueBoxLiee';
  readonly vueBoxLiee = 'vueBoxLiee';
  static readonly x = 'x';
  readonly x = 'x';
  static readonly y = 'y';
  readonly y = 'y';
  static fields() {
    return new VueBoxGen_();
  }
}

