/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface SrAvenantPojoGen { 
    ancienBoxId?: number;
    ancienBoxInstanceLabel?: string;
    ancienClientId?: number;
    ancientClientInstanceLabel?: string;
    commentaire1?: string;
    commentaire2?: string;
    contratId?: number;
    contratInstanceLabel?: string;
    created?: Date;
    createdBy?: string;
    dateAvenant?: Date;
    id?: number;
    instanceLabel?: string;
    nouveauBoxId?: number;
    nouveauBoxInstanceLabel?: string;
    nouveauClientId?: number;
    nouveauClientInstanceLabel?: string;
    prixRemiseHt?: number;
    remise?: number;
    typeAvenantId?: string;
    typeAvenantInstanceLabel?: string;
    updated?: Date;
    updatedBy?: string;
    versionNum?: number;
}

export class SrAvenantPojoGen_ {
  static readonly ancienBoxId = 'ancienBoxId';
  readonly ancienBoxId = 'ancienBoxId';
  static readonly ancienBoxInstanceLabel = 'ancienBoxInstanceLabel';
  readonly ancienBoxInstanceLabel = 'ancienBoxInstanceLabel';
  static readonly ancienClientId = 'ancienClientId';
  readonly ancienClientId = 'ancienClientId';
  static readonly ancientClientInstanceLabel = 'ancientClientInstanceLabel';
  readonly ancientClientInstanceLabel = 'ancientClientInstanceLabel';
  static readonly commentaire1 = 'commentaire1';
  readonly commentaire1 = 'commentaire1';
  static readonly commentaire2 = 'commentaire2';
  readonly commentaire2 = 'commentaire2';
  static readonly contratId = 'contratId';
  readonly contratId = 'contratId';
  static readonly contratInstanceLabel = 'contratInstanceLabel';
  readonly contratInstanceLabel = 'contratInstanceLabel';
  static readonly created = 'created';
  readonly created = 'created';
  static readonly createdBy = 'createdBy';
  readonly createdBy = 'createdBy';
  static readonly dateAvenant = 'dateAvenant';
  readonly dateAvenant = 'dateAvenant';
  static readonly id = 'id';
  readonly id = 'id';
  static readonly instanceLabel = 'instanceLabel';
  readonly instanceLabel = 'instanceLabel';
  static readonly nouveauBoxId = 'nouveauBoxId';
  readonly nouveauBoxId = 'nouveauBoxId';
  static readonly nouveauBoxInstanceLabel = 'nouveauBoxInstanceLabel';
  readonly nouveauBoxInstanceLabel = 'nouveauBoxInstanceLabel';
  static readonly nouveauClientId = 'nouveauClientId';
  readonly nouveauClientId = 'nouveauClientId';
  static readonly nouveauClientInstanceLabel = 'nouveauClientInstanceLabel';
  readonly nouveauClientInstanceLabel = 'nouveauClientInstanceLabel';
  static readonly prixRemiseHt = 'prixRemiseHt';
  readonly prixRemiseHt = 'prixRemiseHt';
  static readonly remise = 'remise';
  readonly remise = 'remise';
  static readonly typeAvenantId = 'typeAvenantId';
  readonly typeAvenantId = 'typeAvenantId';
  static readonly typeAvenantInstanceLabel = 'typeAvenantInstanceLabel';
  readonly typeAvenantInstanceLabel = 'typeAvenantInstanceLabel';
  static readonly updated = 'updated';
  readonly updated = 'updated';
  static readonly updatedBy = 'updatedBy';
  readonly updatedBy = 'updatedBy';
  static readonly versionNum = 'versionNum';
  readonly versionNum = 'versionNum';
  static fields() {
    return new SrAvenantPojoGen_();
  }
}

