import {Injectable} from '@angular/core';
import {LoginForm} from '../../authentication/login/login.component';
import {isNil} from 'lodash';
import {HttpClient, HttpResponse} from '@angular/common/http';
import {Router} from '@angular/router';
import {RoleGen, UtilisateurGen, UtilisateurServiceGen} from '../../shared/generated';
import {Observable} from 'rxjs';
import {enumEquals} from "../../shared/ascore/models/ascore-model-util";

@Injectable({
  providedIn: 'root'
})
export class UtilisateurCourantService {

  private static nbInstanciation: number = 0;
  private static utilisateurCourant: UtilisateurGen = null;

  constructor(protected httpClient: HttpClient,
              public router: Router,
              public utilisateurService: UtilisateurServiceGen) {
    UtilisateurCourantService.nbInstanciation++;
  }

  public updateUtilisateurCourant(utilisateurCourant: UtilisateurGen): void {
    UtilisateurCourantService.utilisateurCourant = utilisateurCourant;
    if (utilisateurCourant == null) {
      sessionStorage.removeItem('utilisateur');
    } else {
      sessionStorage.setItem('utilisateur', JSON.stringify(utilisateurCourant));
    }
  }

  public getUtilisateurCourant(): UtilisateurGen {
    let result: UtilisateurGen;
    if (!isNil(UtilisateurCourantService.utilisateurCourant?.id)) {
      result = UtilisateurCourantService.utilisateurCourant;
    } else {
      UtilisateurCourantService.utilisateurCourant = JSON.parse(sessionStorage.getItem('utilisateur')) as UtilisateurGen;
      result = UtilisateurCourantService.utilisateurCourant;
      console.log('utilisateur courant récupéré du session storage = ', result?.username);
    }
    return result;
  }

  login(loginForm: LoginForm): void {
    this.httpClient.post<void>('/api/login', loginForm, {observe: 'response'}).subscribe(response => {
      sessionStorage.setItem('jwt_token', response.headers.get('authorization'));
      this.utilisateurService.findByUsername(loginForm.username).subscribe(result => {
        const utilisateur = result as UtilisateurGen;
        this.updateUtilisateurCourant(utilisateur);
        const url = sessionStorage.getItem('routeBeforeLogin');
        this.router.navigate([isNil(url) ? '/' : url]);
        sessionStorage.removeItem('routeBeforeLogin');
      });
    }, () => {
    });
  }

  verifieLogin(param: LoginForm): Observable<HttpResponse<void>> {
    return this.httpClient.post<void>('/api/login', param, {observe: 'response'});
  }

  reset(): void {
    this.updateUtilisateurCourant(null);
    this.clearSessionStorageExceptRouteBeforeLogin();
  }

  logout(): void {
    this.reset();
    this.router.navigate(['/auth/connexion']);
  }

  utilisateurCourantHasRole(role: RoleGen): boolean {
    //vérifier si cette modif fonctionne
    return !isNil((this.getUtilisateurCourant().listRole as unknown as RoleGen[])?.find(value => enumEquals(value, role)));
  }

  private clearSessionStorageExceptRouteBeforeLogin() {
    const routeBeforeLogin = sessionStorage.getItem('routeBeforeLogin');
    sessionStorage.clear();
    if (!isNil(routeBeforeLogin)) {
      sessionStorage.setItem('routeBeforeLogin', routeBeforeLogin);
    }
  }

  utilisateurCourantContainsRole(listRole: RoleGen[]): boolean {
    return !isNil(listRole) && UtilisateurCourantService.intersect(listRole, (this.getUtilisateurCourant()?.listRole as unknown as any[]).map(role => role.id)).length > 0
  }

  private static intersect(a: any[], b: any[]): any[] {
    const setB = new Set(b);
    return [...new Set(a)].filter(x => setB.has(x));
  }
}
