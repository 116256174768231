/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ModePaiementGen } from './mode-paiement.gen.model';
import { SortObjectGen } from './sort-object.gen.model';
import { PageableObjectGen } from './pageable-object.gen.model';


export interface PageModePaiementGen { 
    content?: Array<ModePaiementGen>;
    empty?: boolean;
    first?: boolean;
    last?: boolean;
    number?: number;
    numberOfElements?: number;
    pageable?: PageableObjectGen;
    size?: number;
    sort?: SortObjectGen;
    totalElements?: number;
    totalPages?: number;
}

export class PageModePaiementGen_ {
  static readonly content = 'content';
  readonly content = 'content';
  static readonly empty = 'empty';
  readonly empty = 'empty';
  static readonly first = 'first';
  readonly first = 'first';
  static readonly last = 'last';
  readonly last = 'last';
  static readonly number = 'number';
  readonly number = 'number';
  static readonly numberOfElements = 'numberOfElements';
  readonly numberOfElements = 'numberOfElements';
  static readonly pageable = 'pageable';
  readonly pageable = 'pageable';
  static readonly size = 'size';
  readonly size = 'size';
  static readonly sort = 'sort';
  readonly sort = 'sort';
  static readonly totalElements = 'totalElements';
  readonly totalElements = 'totalElements';
  static readonly totalPages = 'totalPages';
  readonly totalPages = 'totalPages';
  static fields() {
    return new PageModePaiementGen_();
  }
}

