import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';

import {AlertModule, BreadcrumbModule, CardModule, ModalModule} from './flatable-components';
import {DataFilterPipe} from './flatable-components/data-table/data-filter.pipe';
import {PERFECT_SCROLLBAR_CONFIG, PerfectScrollbarConfigInterface, PerfectScrollbarModule} from 'ngx-perfect-scrollbar';
import {ClickOutsideModule} from 'ng-click-outside';

import {SpinnerComponent} from './flatable-components/spinner/spinner.component';
import {ToastComponent} from './flatable-components/toast/toast.component';
import {ToastService} from './flatable-components/toast/toast.service';
import {GalleryComponent} from './flatable-components/gallery/gallery.component';
import {LightboxModule} from 'ngx-lightbox';
import {AsCoreModule} from './ascore/ascore.module';


const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true
};

@NgModule({
  imports: [
    CommonModule,
    PerfectScrollbarModule,
    FormsModule,
    ReactiveFormsModule,
    AlertModule,
    CardModule,
    BreadcrumbModule,
    ModalModule,
    ClickOutsideModule,
    LightboxModule,
    AsCoreModule
  ],
  exports: [
    CommonModule,
    PerfectScrollbarModule,
    FormsModule,
    ReactiveFormsModule,
    AlertModule,
    CardModule,
    BreadcrumbModule,
    ModalModule,
    DataFilterPipe,
    ClickOutsideModule,
    SpinnerComponent,
    GalleryComponent,
    ToastComponent
  ],
  declarations: [
    DataFilterPipe,
    SpinnerComponent,
    ToastComponent,
    GalleryComponent
  ],
  providers: [
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
    },
    ToastService
  ]
})
export class SharedModule {
}
