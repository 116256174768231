/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { BoxGen } from './box.gen.model';
import { ClientGen } from './client.gen.model';


export interface ReservationGen { 
    box: BoxGen;
    client: ClientGen;
    created: Date;
    createdBy: string;
    dateEntree: Date;
    dateReservation: Date;
    dtype?: string;
    id?: number;
    instanceLabel: string;
    updated?: Date;
    updatedBy?: string;
    versionNum?: number;
}

export class ReservationGen_ {
  static readonly box = 'box';
  readonly box = 'box';
  static readonly client = 'client';
  readonly client = 'client';
  static readonly created = 'created';
  readonly created = 'created';
  static readonly createdBy = 'createdBy';
  readonly createdBy = 'createdBy';
  static readonly dateEntree = 'dateEntree';
  readonly dateEntree = 'dateEntree';
  static readonly dateReservation = 'dateReservation';
  readonly dateReservation = 'dateReservation';
  static readonly dtype = 'dtype';
  readonly dtype = 'dtype';
  static readonly id = 'id';
  readonly id = 'id';
  static readonly instanceLabel = 'instanceLabel';
  readonly instanceLabel = 'instanceLabel';
  static readonly updated = 'updated';
  readonly updated = 'updated';
  static readonly updatedBy = 'updatedBy';
  readonly updatedBy = 'updatedBy';
  static readonly versionNum = 'versionNum';
  readonly versionNum = 'versionNum';
  static fields() {
    return new ReservationGen_();
  }
}

