/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface SrReservationPojoGen { 
    boxId?: number;
    boxInstanceLabel?: string;
    clientId?: number;
    clientInstanceLabel?: string;
    created?: Date;
    createdBy?: string;
    dateEntree?: Date;
    dateReservation?: Date;
    id?: number;
    instanceLabel?: string;
    updated?: Date;
    updatedBy?: string;
    versionNum?: number;
}

export class SrReservationPojoGen_ {
  static readonly boxId = 'boxId';
  readonly boxId = 'boxId';
  static readonly boxInstanceLabel = 'boxInstanceLabel';
  readonly boxInstanceLabel = 'boxInstanceLabel';
  static readonly clientId = 'clientId';
  readonly clientId = 'clientId';
  static readonly clientInstanceLabel = 'clientInstanceLabel';
  readonly clientInstanceLabel = 'clientInstanceLabel';
  static readonly created = 'created';
  readonly created = 'created';
  static readonly createdBy = 'createdBy';
  readonly createdBy = 'createdBy';
  static readonly dateEntree = 'dateEntree';
  readonly dateEntree = 'dateEntree';
  static readonly dateReservation = 'dateReservation';
  readonly dateReservation = 'dateReservation';
  static readonly id = 'id';
  readonly id = 'id';
  static readonly instanceLabel = 'instanceLabel';
  readonly instanceLabel = 'instanceLabel';
  static readonly updated = 'updated';
  readonly updated = 'updated';
  static readonly updatedBy = 'updatedBy';
  readonly updatedBy = 'updatedBy';
  static readonly versionNum = 'versionNum';
  readonly versionNum = 'versionNum';
  static fields() {
    return new SrReservationPojoGen_();
  }
}

