import { AfterViewInit, Component, Input, OnInit, ViewChild } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup } from '@angular/forms';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { AsCoreFormControl } from '../ascore-input/AsCoreFormControl';
import { ZXingScannerComponent } from '@zxing/ngx-scanner';
import { BarcodeFormat } from '@zxing/library';

/**
 * Ce composant fonctionne mieux sur une tablette/téléphone qu'avec une webcam en particulier parce que les premiers
 * font une mise au point dynamique et que si l'image est floue, la détection échoue.
 * Liste des formats disponibles (1D et 2D) : https://github.com/zxing-js/library/blob/master/src/core/BarcodeFormat.ts
 * ATTENTION : avec Chrome, il faut des échanges en HTTPS, sinon on n'a pas accès aux périphériques audio/vidéo
 * Workaround (par exemple pour tester sur un téléphone) :
 * https://stackoverflow.com/questions/34197653/getusermedia-in-chrome-47-without-using-https/34198101#34198101
 */

@Component({
  selector: 'ascore-barcode-scanner-modal',
  templateUrl: './ascore-barcode-scanner-modal.component.html',
  styleUrls: ['./ascore-barcode-scanner-modal.component.scss']
})
export class AsCoreBarcodeScannerModalComponent implements OnInit, AfterViewInit {

  faTimes = faTimes;

  @ViewChild('scanner', {static: false})
  scanner: ZXingScannerComponent;

  @Input()
  closeOnScanSuccess = true

  @Input()
  allowedFormats = [BarcodeFormat.EAN_13];

  @Input()
  message: string;

  @Input()
  labelOk = 'Ok';

  @Input()
  labelAnnuler = 'Annuler';

  @Input()
  titre = 'Scan d\'un code barre';

  @Input()
  btnClass: typeBtn = 'btn-primary';

  form: FormGroup;
  erreur: string;

  constructor(public modal: NgbActiveModal,
              private fb: FormBuilder) {
  }

  ngOnInit(): void {
    this.form = this.fb.group({
      code: AsCoreFormControl.init().required().label('Code scanné')
    });
  }

  ngAfterViewInit(): void {
    this.scanner.camerasNotFound.subscribe(next => {
      this.erreur = 'Aucune caméra détectée/accessible'
    })
    this.scanner.askForPermission().then(value => {
      if (!value) {
        this.erreur = 'L\'accès à la caméra n\'est pas autorisé'
      }
    })
  }

  getCode(): string {
    return this.form?.get('code')?.value;
  }

  scanSuccess(code: string): void {
    this.form.get('code').setValue(code);
    if (this.closeOnScanSuccess) {
      this.modal.close(code)
    }
  }
}

export type typeBtn = 'btn-danger' | 'btn-primary';
