<ascore-card [title]="title" [header]="header" [cardClass]="addClass" [customTitle]="customTitle"
             [closable]="closable">
  <ngx-spinner name="ascore-search-spinner" [fullScreen]="false" color="#ec1d25"
               type="ball-scale-multiple"></ngx-spinner>
  <div *ngIf="!hideSearchForm">
    <div class="form-group form-inline p-0">
      <form [formGroup]="searchForm" class="w-100" (ngSubmit)="search()">

        <ng-content></ng-content>

        <div class="row mt-2 mb-2">
          <div class="col">
            <button *ngIf="buttonAddVisible"
                    class="btn btn-primary align-self-start ml-2"
                    style="background-color: var(--red) ; border-color: var(--red)"
                    type="button"
                    ngbTooltip="Créer nouveau"
                    (click)="add()">
              <fa-icon [icon]="faPlus"></fa-icon>
              Ajouter
            </button>
          </div>

          <div class="col text-right">
            <button class="btn btn-primary align-self-start" type="submit">
              <fa-icon [icon]="faSearch"></fa-icon>
              Rechercher
            </button>
            <button class="btn btn-primary align-self-start ml-1 mr-2"
                    ngbTooltip="Effacer les filtres" placement="left"
                    type="button"
                    (click)="resetSearch()">
              <fa-icon [icon]="faEraser"></fa-icon>
            </button>
            <button *ngIf="withExport" class="btn btn-primary align-self-start ml-1 mr-2"
                    ngbTooltip="Exporter" placement="left" type="button"
                    (click)="export()">
              <fa-icon [icon]="faDownload"></fa-icon>
            </button>
            <ng-container *ngIf="customAction" [ngTemplateOutlet]="customActionTemplate"></ng-container>

            <button *ngIf="plusDeFiltreTemplate != null" class="btn btn-sm btn-info align-self-start ml-1"
                    type="button"
                    [ngbTooltip]="isPlusDeFiltreCollapsed ? 'Plus de filtre' : 'Moins de filtres'"
                    placement="left"
                    (click)="isPlusDeFiltreCollapsed = !isPlusDeFiltreCollapsed"
                    [attr.aria-expanded]="!isPlusDeFiltreCollapsed" aria-controls="collapse">
              <fa-icon [icon]="isPlusDeFiltreCollapsed ? faChevronDown : faChevronUp"></fa-icon>
            </button>
          </div>

        </div>
      </form>
    </div>

    <ng-container [ngTemplateOutlet]="customTableHeader"
                  [ngTemplateOutletContext]="{additionalInfo: additionalInfo}">
    </ng-container>
  </div>

  <div class="table-container">

    <ascore-table [columns]="columns"
                  [content]="pagedResources.content"
                  [additionalInfo]="additionalInfo"
                  [withSelection]="withSelection"
                  [startWithSelectionChecked]="startWithSelectionChecked"
                  [sort]="pagination.sort"
                  (selectionChangeEvent)="selectionChangeEvent.emit($event)"
                  (sortEvent)="onSort($event)"
                  (openEvent)="open($event)">
    </ascore-table>
  </div>

  <!-- Gestion mode paginé, avec total d'éléments (et donc nb de pages dispo) -->
  <ng-container *ngIf="pagedResources.totalElements !== null">
    <div *ngIf="pagedResources.totalElements === 0" class="text-center p-2">Aucun résultat</div>

    <div *ngIf="pagedResources.totalElements > 0"
         class="ascore-pagination d-flex justify-content-between pt-1 pb-1 pl-2 pr-2">
      <ngb-pagination [collectionSize]="pagedResources.totalElements"
                      [(page)]="pagination.page"
                      [pageSize]="pagination.size"
                      [maxSize]="4"
                      [boundaryLinks]="true"
                      (pageChange)="search()">
      </ngb-pagination>
      <div class="custom-select">
        <span *ngIf="pagedResources.totalElements === 1">1 élément</span>
        <span *ngIf="pagedResources.totalElements > 1">{{pagedResources.totalElements}} éléments</span>
      </div>

      <div flex="1"></div>

      <select class="custom-select custom-page-select"
              [(ngModel)]="pagination.size"
              (change)="paginationSizeChanged()"
              (ngModelChange)="search()">
        <option [ngValue]="10">10 éléments</option>
        <option [ngValue]="20">20 éléments</option>
        <option [ngValue]="50">50 éléments</option>
        <option [ngValue]="100">100 éléments</option>
      </select>
    </div>
  </ng-container>

  <!-- Gestion mode sliced -->
  <ng-container *ngIf="pagedResources.totalElements === undefined">
    <div *ngIf="pagedResources.number === 0 && pagedResources.numberOfElements === 0" class="text-center p-2">
      Aucun résultat
    </div>

    <div *ngIf="pagedResources.number > 0 || pagedResources.numberOfElements > 0"
         class="ascore-pagination d-flex justify-content-between pt-1 pb-1 pl-2 pr-2">

      <ascore-slice-pagination [first]="pagedResources.first"
                               [last]="pagedResources.last"
                               [(page)]="pagination.page"
                               (pageChange)="search()">
      </ascore-slice-pagination>

      <div flex="1"></div>

      <div *ngIf="this.withCount">
        <button (click)="updateTotalCount()" class="btn btn-sm btn-outline-secondary">
          {{totalCount ? 'Total : ' + totalCount + ' Eléments' : 'Compter'}}
          <fa-icon [icon]="iconCount"></fa-icon>
        </button>
      </div>

      <div flex="1"></div>

      <select class="custom-select custom-page-select"
              [(ngModel)]="pagination.size"
              (ngModelChange)="search()">
        <option [ngValue]="10">10 éléments</option>
        <option [ngValue]="20">20 éléments</option>
        <option [ngValue]="50">50 éléments</option>
        <option [ngValue]="100">100 éléments</option>
      </select>
    </div>
  </ng-container>
</ascore-card>

<ng-template #btnSupprimerTpl let-element="element">
  <button class="btn btn-sm btn-danger" type="button" (click)="delete(element)" *ngIf="withDelete">
    <fa-icon [icon]="faTrash"></fa-icon>
  </button>
</ng-template>
