import * as moment from 'moment';
import {Moment} from 'moment';
import {isNil} from 'lodash';
import {formatDate} from '@angular/common';


export function formatDateHeureToStringFrancais(date: Date): string {
  if (isNil(date)) {
    return '';
  }
  const infoDate = formatDate(date, 'dd/MM/yyyy', 'fr_FR');
  const infoHeure = formatDate(date, 'HH:mm:ss', 'fr_FR');
  return infoHeure === '00:00:00' ? infoDate : infoDate + ' à ' + infoHeure;
}

export function formatDateToStringView(date: string | Moment): string {
  return date ? moment(date).format('DD/MM/YY à HH:mm') : '';
}

export function formatDateWithoutTimeToStringView(date: Date): string {
  return date ? moment(date).format('DD/MM/YY') : '';
}

/*
 * Si on envoie directement le getRawValue, lors du stringify,
 * javascript fait un toISOString sur la date, ce qui nous donnes une date UTC, c'est à dire à 13h00 et non minuit en +11
 * renvoie donc le format correct d'une date au server sans timezone
 */
export function formatDateToStringLocale(date: string | Moment): string {
  return date ? moment(date).format('YYYY-MM-DDTHH:mm:ss') : null;
}

// les LocalDateTime en 00:00:00 sont considérés comme des date et non des DateTime
export function looksLikeDate(date: string | Moment): boolean {
  return !isNil(date) &&
    dateStartWithRationalCentury(date.toString()) &&
    (moment(date, 'YYYY-MM-DD', true).isValid() ||
      (moment(date, 'YYYY-MM-DDTHH:mm:ss', true).isValid() &&
        moment(date).hours() === 0 && moment(date).minutes() === 0 &&
        moment(date).seconds() === 0));
}

export function looksLikeDateTime(date: string | Moment): boolean {
  return !isNil(date) &&
    dateStartWithRationalCentury(date.toString()) &&
    (moment(date, 'YYYY-MM-DDTHH:mm:ss', true).isValid() ||
      moment(date, 'YYYY-MM-DDTHH:mm:ss.SSSS', true).isValid());
}

function dateStartWithRationalCentury(date: string): boolean {
  return !isNil(date) &&
    (date.startsWith('19') || date.startsWith('20') || date.startsWith('21'));
}

export function todayForInput(): string {
  return formatDateToAsCoreInput(new Date());
}

export function todayTimeForInput(): string {
  return formatTimeToAsCoreInput(new Date());
}

export function formatDateToAsCoreInput(date: Date): string {
  return formatDate(date, 'yyyy-MM-dd', 'fr-FR');
}

export function formatTimeToAsCoreInput(date: Date): string {
  return formatDate(date, 'HH:mm', 'fr-FR');
}
