import { WithSearchAudit } from '../../service/api/with-search-audit';
import { AsCoreTab } from '../ascore-tab/ascore-tab.model';

export class AuditUtil {

  public static tabAudit(entityId: number, withSearchAudit: WithSearchAudit, titre: string = 'Historique'): AsCoreTab {
    return {
      type: 'audit',
      withSearchAudit: withSearchAudit,
      titre: titre,
      columns: [],
      formControlName: ''
    }
  }

}
