<div class="modal-header">
  <h6 class="modal-title">{{titre}}</h6>
  <button type="button" class="close customClose" aria-label="Close button" aria-describedby="modal-title"
          (click)="modal.dismiss()">
    <fa-icon [icon]="faTimes"></fa-icon>
  </button>
</div>
<div class="modal-body" [formGroup]="form">

  <signature-pad #signature style="background-color: whitesmoke"
                 [options]="signaturePadOptions"
                 (drawEnd)="drawComplete($event)">
  </signature-pad>

</div>

<div class="modal-footer">
  <button *ngIf="labelAnnuler" type="button" class="btn btn-outline-secondary"
          (click)="modal.dismiss()">{{labelAnnuler}}
  </button>
  <button type="button" class="btn" [class]="btnClass"
          #btnOK
          [disabled]="this.form.invalid"
          (click)="modal.close(this.form.get('signatureJpegData').value)">
    {{labelOk}}
  </button>
</div>
