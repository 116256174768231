import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  TemplateRef
} from '@angular/core';
import { AsCoreColumn } from '../ascore-search/ascore-search.component';
import { faArrowDown, faArrowUp } from '@fortawesome/free-solid-svg-icons';
import { SortEvent } from '../../directives/sortable-header.directive';
import { DateFormatPipe } from '../../pipe/date-format.pipe';
import { DateTimeFormatPipe } from '../../pipe/date-time-format.pipe';
import { looksLikeDate, looksLikeDateTime } from '../../utils/date-util';
import { getValueRecursively } from '../../utils/object-util';
import { isNil } from 'lodash';
import { IdInstanceLabel } from '../../models/id-instance-label';
import { isBlank } from '../../utils/string-util';
import { ContentChangeEvent, SelectionChangeEvent, SelectTableEvent } from './ascore-table.model';

@Component({
  selector: 'ascore-table',
  templateUrl: './ascore-table.component.html',
  styleUrls: ['./ascore-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default
})
export class AsCoreTableComponent implements OnChanges {

  constructor(private dateFormat: DateFormatPipe,
              private dateTimeFormat: DateTimeFormatPipe) {
  }

  faArrowUp = faArrowUp;
  faArrowDown = faArrowDown;

  @Input()
  footerTpl: TemplateRef<any>;

  @Input()
  showHeader = true;

  @Input()
  withSelection = false;

  @Input()
  startWithSelectionChecked = false;

  @Input()
  columns: AsCoreColumn[] = [];

  @Input()
  content: any[] = [];

  /** exemple : ["title,asc", "dateEmission,desc"] */
  @Input()
  sort: Array<string>;

  @Input()
  classLastLine: string;

  @Input()
  additionalInfo: Map<string, any>;

  @Output()
  sortEvent = new EventEmitter<SortEvent>();

  @Output()
  openEvent = new EventEmitter<SelectTableEvent>();

  @Output()
  selectionChangeEvent = new EventEmitter<SelectionChangeEvent>();

  @Output()
  contentChangeEvent = new EventEmitter<ContentChangeEvent>();

  // Optimisation perf angular ngFor
  trackById = (index: number, item: any) => item.id;

  public getValue = (element: IdInstanceLabel, fieldName: string): string => {

    if (isNil(fieldName)) {
      return '';
    }

    const value = element[fieldName];

    if (fieldName?.includes('.')) {
      return getValueRecursively(element, fieldName, this.getValue);
    } else if (value?.hasOwnProperty('instanceLabel')) {
      return value.instanceLabel;
    } else if (value instanceof Boolean || typeof value === 'boolean') {
      return value ? 'Oui' : '-';
    }
    if (looksLikeDateTime(value)) {
      return this.dateTimeFormat.transform(value);
    }
    if (looksLikeDate(value)) {
      return this.dateFormat.transform(value);
    }

    return value;
  }

  sendSort(event: SortEvent): void {
    const suffixeDirection = isBlank(event.direction) ? '' : ',' + event.direction;
    this.sort = [event.column + suffixeDirection];
    this.sortEvent.emit(event);
  }

  isAllChecked(): boolean {
    return this.content?.every(_ => _.state);
  }

  toggleSelection(target: HTMLInputElement): void {
    this.content?.forEach(row => row.state = target.checked);
    this.sendSelectionChanged();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.startWithSelectionChecked) {
      this.content?.forEach(row => row.state = true);
    }
    this.sendContentChanged();
    this.sendSelectionChanged();
  }

  private sendSelectionChanged(): void {
    this.selectionChangeEvent.emit({selection: (this.content || [])?.filter(value => value.state === true)});
  }

  private sendContentChanged(): void {
    this.contentChangeEvent.emit({content: this.content});
  }

  getClassTr(last: boolean): any {
    if (last) {
      return this.classLastLine;
    } else {
      return undefined;
    }
  }
}
