<div class="table-responsive">
    <table class="table table-xs table-striped table-hover">
        <thead *ngIf="showHeader">
        <tr>
            <th *ngIf="withSelection" scope="col">
                <div class="form-check">
                    <input class="form-check-inline" type="checkbox" [checked]="isAllChecked()"
                           containerDivCustomClass="pb-0"
                           (change)="toggleSelection($event.target)">
                </div>
            </th>
            <th *ngFor="let column of columns;" scope="col"
                [asCoreSortable]="column.sortFieldName"
                (sort)="sendSort($event)" [width]="column.width ? column.width : 'auto'"
                style="white-space: break-spaces">
                {{column.header}}
                <fa-icon *ngIf="column.sortFieldName && sort && sort.length>0 && sort[0]===column.sortFieldName+',asc'"
                         [icon]="faArrowUp"></fa-icon>
                <fa-icon *ngIf="column.sortFieldName && sort && sort.length>0 && sort[0]===column.sortFieldName+',desc'"
                         [icon]="faArrowDown "></fa-icon>
            </th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let element of content; let index = index; let last = last;trackBy: trackById"
            [class]="getClassTr(last)"
            (dblclick)="openEvent.emit({entity: element, index: index})">
            <td *ngIf="withSelection">
                <div class="form-check">
                    <input class="form-check-inline" type="checkbox" name="selectCheckBox[]" value="{{element.id}}"
                           containerDivCustomClass="pb-0"
                           [(ngModel)]="element.state" (change)="sendSelectionChanged()"/>
                </div>
            </td>
            <td *ngFor="let column of columns" [class.template-container]="column.template != null">
                <div *ngIf="column.template">
                    <!-- note : problème avec ngbTooltip qui empêche de cliquer sur le contenu du tableau -->
                    <ng-container
                            *ngTemplateOutlet="column.template;context:{element: element, index: index, value: getValue(element, column.fieldName), additionalInfo: additionalInfo}">
                    </ng-container>
                </div>
                <div class="pre-line" *ngIf="!column.template"
                     [ngbTooltip]="getValue(element, column.tooltipFieldName)">
                    {{getValue(element, column.fieldName)}}
                </div>
            </td>
        </tr>
        </tbody>
        <tfoot *ngIf="footerTpl">
        <tr>
            <td *ngIf="withSelection" [colSpan]="columns.length + (withSelection? 1 : 0)">
                <ng-container [ngTemplateOutlet]="footerTpl"></ng-container>
            </td>
        </tr>
        </tfoot>
    </table>
</div>
