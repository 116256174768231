import {Injectable} from '@angular/core';
import {isNil} from 'lodash';
import {UtilisateurCourantService} from '../../../../administration/referentiel/utilisateur-courant.service';
import {RoleGen} from '../../../../shared/generated';

export interface NavigationItem {
  id: string;
  title: string;
  type: 'item' | 'collapse' | 'group';
  translate?: string;
  icon?: string;
  hidden?: boolean;
  url?: string;
  param?: string;
  classes?: string;
  exactMatch?: boolean;
  external?: boolean;
  target?: boolean;
  breadcrumbs?: boolean;
  function?: any;
  badge?: {
    title?: string;
    type?: string;
  };
  children?: Navigation[];
}

export interface Navigation extends NavigationItem {
  children?: NavigationItem[];
  listRole?: RoleGen[];
}

const navigationItems = [
  {
    id: 'id-user',
    title: '',
    type: 'group',
    children: [
      {
        id: 'mon-compte',
        title: 'Mon compte',
        type: 'item',
        url: '/mon-compte',
        classes: 'nav-item',
        icon: 'fas fa-user'
      }
    ]
  },
  {
    id: 'dashboard',
    title: 'Tableau de bord',
    type: 'group',
    children: [
      {
        id: 'box',
        title: 'Plans des box',
        type: 'item',
        url: '/dashboard/plans',
        classes: 'nav-item',
        icon: 'fa fa-map',
      }
    ]
  },
  {
    "id": "location",
    "title": "Location",
    "type": "group",
    "icon": null,
    "url": null,
    "role": null,
    "children": [{
      "id": "devis",
      "title": "Devis",
      "type": "item",
      "icon": "fa fa-file-invoice",
      "url": "devis",
    },
      {
        "id": "contrats",
        "title": "Contrats",
        "type": "item",
        "icon": "fa fa-file-contract",
        "url": "contrats",
      },
      {
        "id": "avenants",
        "title": "Avenants",
        "type": "item",
        "icon": "fa fa-edit",
        "url": "avenants",
      },
      {
        "id": "factures",
        "title": "Factures",
        "type": "item",
        "icon": "fa fa-file-invoice-dollar",
        "url": "factures",
      },
      {
        "id": "reservations",
        "title": "Réservations",
        "type": "item",
        "icon": "fa fa-calendar-check",
        "url": "reservations",
      },
      {
        "id": "relances-devis",
        "title": "Relances",
        "type": "item",
        "icon": "fa fa-envelope-open-text",
        "url": "relances-devis",
      },
      {
        id: 'refreshFacture',
        type: 'item',
        url: '/factures/force-facturation',
        icon: 'fas fa-sync red',
        title: 'Forcer facturation',
        classes: 'nav-item',
        listRole: [RoleGen.ADMIN]
      },
    ]
  },
  {
    "id": "gestion",
    "title": "Gestion",
    "type": "group",
    "icon": null,
    "url": null,
    "role": null,
    "children": [
      {
        "id": "box",
        "title": "Box",
        "type": "item",
        "icon": "fa fa-square",
        "url": "box",
        "children": null
      },
      {
        "id": "client",
        "title": "Clients",
        "type": "item",
        "icon": "fa fa-users",
        "url": "clients",
        "children": null
      },
      {
        "id": "activite-client",
        "title": "Activités",
        "type": "item",
        "icon": "fa fa-tasks",
        "url": "activites-client",
        "children": null
      }]
  },
  {
    "id": "administration",
    "title": "Administration",
    "type": "group",
    "icon": null,
    "url": null,
    listRole: [RoleGen.ADMIN],
    "children": [
      {
        "id": "utilisateurs",
        "title": "Utilisateurs",
        "type": "item",
        "icon": "fa fa-user-circle",
        "url": "administration/utilisateurs",
        "children": null
      },
      {
        "id": "sites",
        "title": "Sites",
        "type": "item",
        "icon": "fa fa-industry",
        "url": "administration/sites",
        "children": null
      },
      {
        "id": "niveaux",
        "title": "Niveaux",
        "type": "item",
        "icon": "fa fa-bars",
        "url": "administration/niveaux",
        "children": null
      },
      {
        "id": "sci",
        "title": "SCI",
        "type": "item",
        "icon": "fa fa-university",
        "url": "administration/sci",
        "children": null
      },
      {
        "id": "articles",
        "title": "Articles",
        "type": "item",
        "icon": "fa fa-shopping-cart",
        "url": "administration/articles",
        "children": null
      },
      {
        "id": "audit",
        "title": "Audit",
        "type": "item",
        "icon": "fa fa-history",
        "url": "administration/audit",
        "children": null
      }]
  },
  {
    "id": "statistiques",
    "title": "Statistiques",
    "type": "group",
    "icon": null,
    "url": null,
    "role": null,
    "children": [
      {
        id: 'stat-gestion',
        title: 'Gestion',
        type: 'item',
        url: '/dashboard/statistiques',
        param: 'gestion',
        classes: 'nav-item',
        icon: 'fa fa-chart-line',
      },
      {
        id: 'stat-commercial',
        title: 'Commercial',
        type: 'item',
        url: '/dashboard/statistiques',
        param: 'commercial',
        classes: 'nav-item',
        icon: 'fa fa-chart-line',
      },
      {
        id: 'stat-exploitation',
        title: 'Exploitation',
        type: 'item',
        url: '/dashboard/statistiques',
        param: 'exploitation',
        classes: 'nav-item',
        icon: 'fa fa-chart-line',
      },
    ]
  }
];


@Injectable()
export class NavigationItem {

  constructor(public utilisateurCourantService: UtilisateurCourantService) {
  }

  private isVisible(navigationItem): boolean {
    if (isNil(navigationItem)) {
      return false;
    }
    return isNil(navigationItem.listRole) || this.utilisateurCourantService.utilisateurCourantContainsRole(navigationItem.listRole);
  }

  private filtreNavigationItemsTree(navigationItems: Array<any>): Array<any> {
    if (isNil(navigationItems)) {
      return navigationItems;
    }
    let result = navigationItems.filter(navigationItem => this.isVisible(navigationItem));
    result?.forEach(navigationItem => navigationItem.children = this.filtreNavigationItemsTree(navigationItem.children));
    return result;
  }

  public get() {
    let nav = [].concat(navigationItems)//.concat(generatedNavigationItems)
    return this.filtreNavigationItemsTree(nav);
  }
}
