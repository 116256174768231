import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { MessageService } from '../shared/ascore/service/message.service';

@Injectable()
export class AuthGuard implements CanActivate {

  constructor(private router: Router, private messageService: MessageService) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    if (sessionStorage.getItem('utilisateur')) {
      return true
    }
    //mis en commentaire, sinon on a le message alors qu'on vient d'arriver sur la page de login :
    //this.messageService.showError('Vous devez vous identifier pour accéder à cette page !')
    this.router.navigate(['/auth/connexion'])
    return false
  }

}
