/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface SrDevisLocationPojoGen { 
    clientId?: number;
    clientInstanceLabel?: string;
    created?: Date;
    createdBy?: string;
    dateBesoin?: Date;
    dateEmission?: Date;
    dateMotif?: Date;
    etatInstanceLabel?: string;
    etatNom?: string;
    id?: number;
    instanceLabel?: string;
    modeCommunication?: string;
    motifInstanceLabel?: string;
    motifNom?: string;
    nbDocument?: number;
    nonArchive?: boolean;
    numero?: string;
    totalTtc?: number;
    transforme?: boolean;
    transmis?: boolean;
    updated?: Date;
    updatedBy?: string;
    utilisateurId?: number;
    utilisateurInstanceLabel?: string;
    versionNum?: number;
}

export class SrDevisLocationPojoGen_ {
  static readonly clientId = 'clientId';
  readonly clientId = 'clientId';
  static readonly clientInstanceLabel = 'clientInstanceLabel';
  readonly clientInstanceLabel = 'clientInstanceLabel';
  static readonly created = 'created';
  readonly created = 'created';
  static readonly createdBy = 'createdBy';
  readonly createdBy = 'createdBy';
  static readonly dateBesoin = 'dateBesoin';
  readonly dateBesoin = 'dateBesoin';
  static readonly dateEmission = 'dateEmission';
  readonly dateEmission = 'dateEmission';
  static readonly dateMotif = 'dateMotif';
  readonly dateMotif = 'dateMotif';
  static readonly etatInstanceLabel = 'etatInstanceLabel';
  readonly etatInstanceLabel = 'etatInstanceLabel';
  static readonly etatNom = 'etatNom';
  readonly etatNom = 'etatNom';
  static readonly id = 'id';
  readonly id = 'id';
  static readonly instanceLabel = 'instanceLabel';
  readonly instanceLabel = 'instanceLabel';
  static readonly modeCommunication = 'modeCommunication';
  readonly modeCommunication = 'modeCommunication';
  static readonly motifInstanceLabel = 'motifInstanceLabel';
  readonly motifInstanceLabel = 'motifInstanceLabel';
  static readonly motifNom = 'motifNom';
  readonly motifNom = 'motifNom';
  static readonly nbDocument = 'nbDocument';
  readonly nbDocument = 'nbDocument';
  static readonly nonArchive = 'nonArchive';
  readonly nonArchive = 'nonArchive';
  static readonly numero = 'numero';
  readonly numero = 'numero';
  static readonly totalTtc = 'totalTtc';
  readonly totalTtc = 'totalTtc';
  static readonly transforme = 'transforme';
  readonly transforme = 'transforme';
  static readonly transmis = 'transmis';
  readonly transmis = 'transmis';
  static readonly updated = 'updated';
  readonly updated = 'updated';
  static readonly updatedBy = 'updatedBy';
  readonly updatedBy = 'updatedBy';
  static readonly utilisateurId = 'utilisateurId';
  readonly utilisateurId = 'utilisateurId';
  static readonly utilisateurInstanceLabel = 'utilisateurInstanceLabel';
  readonly utilisateurInstanceLabel = 'utilisateurInstanceLabel';
  static readonly versionNum = 'versionNum';
  readonly versionNum = 'versionNum';
  static fields() {
    return new SrDevisLocationPojoGen_();
  }
}

