<div class="modal-header">
  <h6 class="modal-title">{{titre}}</h6>
  <button type="button" class="close customClose" aria-label="Close button" aria-describedby="modal-title"
          (click)="modal.dismiss()">
    <fa-icon [icon]="faTimes" class="tw-text-white"></fa-icon>
  </button>
</div>
<div class="modal-body" [formGroup]="form">
  <div class="alert alert-danger mb-2 font-weight-bold text-center" *ngIf="messageImportant">
    <fa-icon [icon]="faExclamationTriangle"></fa-icon>
    {{messageImportant}}
  </div>
  <div class="message text-center">{{message}}</div>

  <input *ngIf="questionRaison" [type]="typeRaison"
         (keyup.enter)="btnOK.click()"
         formControlName="raison">
</div>

<div class="modal-footer">
  <button *ngIf="labelAnnuler" type="button" class="btn btn-outline-secondary"
          (click)="modal.dismiss()">{{labelAnnuler}}
  </button>
  <button type="button" class="btn" [class]="btnClass"
          #btnOK
          [disabled]="this.form.invalid"
          (click)="modal.close(this.getRaison())">
    {{labelOk}}
  </button>
</div>
