<div class="m-0 p-0" *ngIf="tab.topElementRef">
  <ng-container
          *ngTemplateOutlet="tab.topElementRef">
  </ng-container>
</div>
<div *ngIf="tab.type !=='custom'" class="container-fluid">

  <button *ngIf="tab.type ==='composition' && !tab.readonly && (tab.hideAdd == null || !tab.hideAdd())"
          class="btn btn-sm btn-outline-primary mt-2"
          type="button"
          (click)="add()"
          ngbTooltip="Ajouter">
    <fa-icon [icon]="faPlus"></fa-icon>
    Ajouter
  </button>
  <button *ngFor="let action of tab.customTabActions; let i = index"
          class="btn btn-sm btn-outline-primary mt-2 ml-2"
          [style.right]="(150 + 80 * (i + 1)) + 'px'"
          type="button"
          (click)="triggerActionClick(action)"
          [class]="action.additionalClass"
          [disabled]="(action.actif != undefined && !action.actif) || tab.readonly"
          [ngbTooltip]="action.tooltip">
    <fa-icon [icon]="action.icon"></fa-icon>
    {{ action.name }}
  </button>

  <div class="row">
    <div class="col-sm p-2">
      <ascore-select
              *ngIf="tab.type ==='lien' && tab.withSearch && !tab.readonly && (tab.hideAdd == null || !tab.hideAdd())"
              [withSearch]="tab.withSearch"
              [placeHolder]="tab.placeHolder ? tab.placeHolder : 'Ajouter'"
              [searchFieldName]="tab.searchFieldName"
              [cleanOnSelect]="true"
              [minChar]="0"
              [pageSize]="30"
              [listExclusion]="content()"
              (selectEvent)="select($event)">
      </ascore-select>
    </div>
    <div class="col-sm p-2 text-right">
      <h5>
        <span *ngFor="let tabInfo of tab.customTabInfos" class="badge {{tabInfo.additionalClass}} ml-1"
              [textContent]="tabInfo.value"></span>
      </h5>
    </div>
  </div>
</div>
<div *ngIf="isDataTablePresent()" class="pb-2 table-container mb-2">
  <ascore-table
          [columns]="tab.columns"
          [withSelection]="tab.withSelection"
          [startWithSelectionChecked]="tab.startWithSelectionChecked"
          [content]="content()"
          (openEvent)="open($event)"
          (sortEvent)="sort($event)"
          (selectionChangeEvent)="triggerSelectionChange($event)"
          (contentChangeEvent)="triggerContentChange($event)">
  </ascore-table>
</div>
<div class="container-fluid flex-fill m-0 p-0" *ngIf="tab.type ==='custom'">
  <ng-container
          *ngTemplateOutlet="tab.customElementRef">
  </ng-container>
</div>

<ng-container *ngIf="tab.type ==='audit' && tabAffiche && withSearchAudit">
  <ascore-audit-commit [entityId]="this.form.get('id').value"
                       [withSearchAudit]="withSearchAudit">
  </ascore-audit-commit>
</ng-container>

<ng-template #btnSupprimerTpl let-element="element">
  <button class="btn btn-sm btn-danger tw-rounded-2xl" type="button" (click)="delete(element)"
          *ngIf="tab.type!='consultation' && tab.type!='lectureSeule' && !tab.readonly">
    <fa-icon [icon]="faTrash"></fa-icon>
  </button>
</ng-template>

<ng-template #btnOpenTpl let-element="element" let-index="index">
  <button class="btn btn-sm btn-primary" type="button" (click)="open({entity: element, index: index})">
    <fa-icon [icon]="faEye"></fa-icon>
  </button>
</ng-template>
