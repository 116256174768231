import {Component, EventEmitter, Input, OnInit, Output, TemplateRef} from '@angular/core';
import {NgbDropdownConfig} from '@ng-bootstrap/ng-bootstrap';
import {animate, AUTO_STYLE, state, style, transition, trigger} from '@angular/animations';
import {faTimes} from '@fortawesome/free-solid-svg-icons';
import {Router} from '@angular/router';
import {Location} from '@angular/common';

@Component({
  selector: 'ascore-card',
  templateUrl: './ascore-card.component.html',
  styleUrls: ['./ascore-card.component.scss'],
  providers: [NgbDropdownConfig],
  animations: [
    trigger('collapsedCard', [
      state('collapsed, void',
        style({
          height: '0px'
        })
      ),
      state('expanded',
        style({
          height: AUTO_STYLE
        })
      ),
      transition('collapsed <=> expanded', [
        animate('400ms ease-in-out')
      ])
    ]),
    trigger('cardRemove', [
      state('open', style({
        opacity: 1
      })),
      state('closed', style({
        opacity: 0,
        display: 'none'
      })),
      transition('open <=> closed', animate('400ms'))
    ])
  ]
})

export class AsCoreCardComponent implements OnInit {

  faTimes = faTimes;

  @Input() title: string;
  @Input() cardClass: string;
  @Input() blockClass: string;
  @Input() headerClass: string;
  @Input() options: boolean;
  @Input() header: boolean;
  @Input() customHeader: boolean;
  @Input() cardCaption: string;
  @Input() captionClass: string;
  @Input() isCardFooter: boolean;
  @Input() footerClass: string;
  @Input() status: string = null;
  @Input() closable = true;
  @Input() closeParams = '';
  @Input() history = null;
  @Input() customTitle: TemplateRef<any>;
  @Input() forceShowHistory = false;
  @Input() light = false;

  @Input() customClose = false;
  @Output() closeTriggered = new EventEmitter<void>()

  public animation: string;
  public fullIcon: string;
  public isAnimating: boolean;

  public collapsedCard: string;
  public collapsedIcon: string;

  public loadCard: boolean;

  public cardRemove: string;

  constructor(private router: Router, private location: Location, config: NgbDropdownConfig) {
    config.placement = 'bottom-right';
    this.customHeader = false;
    this.options = false;
    this.header = true;
    this.isCardFooter = false;
    this.title = '';

    this.fullIcon = 'icon-maximize';
    this.isAnimating = false;

    this.collapsedCard = 'expanded';
    this.collapsedIcon = 'icon-minus';

    this.loadCard = false;

    this.cardRemove = 'open';
  }

  ngOnInit(): void {
    if (this.header) {
      this.options = false;
    }

    if (!this.options || this.header || this.customHeader) {
      this.collapsedCard = 'false';
    }
  }

  close(): void {
    if (!this.customClose) {
      this.location.back();
    } else {
      this.closeTriggered.emit()
    }
  }
}
