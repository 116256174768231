import {RouterModule, Routes} from '@angular/router';
import {NgModule} from '@angular/core';

import {UtilisateurSearchComponent} from './utilisateur-search/utilisateur-search.component';
import {UtilisateurDetailComponent} from './utilisateur-detail/utilisateur-detail.component';

const routes: Routes = [
  {
    path: '',
    component: UtilisateurSearchComponent
  },
  {
    path: 'creation',
    component: UtilisateurDetailComponent
  },
  {
    path: ':id',
    component: UtilisateurDetailComponent
  }];

@NgModule({
  imports: [
    RouterModule.forChild(routes)
  ],
  exports: [RouterModule]
})
export class UtilisateurRoutingModule {
}
