/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type MotifDevisGen = 'REFUSE_LOCABOX' | 'BOX_NON_DISPONIBLE' | 'PRIX' | 'AUTRE_SOLUTION' | 'ABANDON_PROJET' | 'AUCUN_RETOUR';

export const MotifDevisGen = {
        REFUSE_LOCABOX: 'REFUSE_LOCABOX' as MotifDevisGen,
        BOX_NON_DISPONIBLE: 'BOX_NON_DISPONIBLE' as MotifDevisGen,
        PRIX: 'PRIX' as MotifDevisGen,
        AUTRE_SOLUTION: 'AUTRE_SOLUTION' as MotifDevisGen,
        ABANDON_PROJET: 'ABANDON_PROJET' as MotifDevisGen,
        AUCUN_RETOUR: 'AUCUN_RETOUR' as MotifDevisGen
        };

export class MotifDevisGen_ {
    static readonly instanceLabel = 'instanceLabel';
    readonly instanceLabel = 'instanceLabel';
static fields() {
return new MotifDevisGen_();
}
}

