<ul class="navbar-nav ml-auto" style="color: #535763">

  <li class="ml-2">{{utilisateurConnecte}}</li>

  <li class="ml-3">

    <h2 style="padding-top: 14px">
      <i class="fas fa-sign-out-alt"
         (click)="logout()"
         style="cursor: pointer;"
         ngbTooltip="Se déconnecter"></i></h2>

  </li>
</ul>
