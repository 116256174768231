/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ContratLocationGen } from './contrat-location.gen.model';
import { BoxGen } from './box.gen.model';


export interface ItemContratGen { 
    box: BoxGen;
    contrat: ContratLocationGen;
    created: Date;
    createdBy: string;
    dtype?: string;
    id?: number;
    instanceLabel: string;
    prixRemiseHt: number;
    remise?: number;
    updated?: Date;
    updatedBy?: string;
    vehiculeAutorise?: string;
    versionNum?: number;
}

export class ItemContratGen_ {
  static readonly box = 'box';
  readonly box = 'box';
  static readonly contrat = 'contrat';
  readonly contrat = 'contrat';
  static readonly created = 'created';
  readonly created = 'created';
  static readonly createdBy = 'createdBy';
  readonly createdBy = 'createdBy';
  static readonly dtype = 'dtype';
  readonly dtype = 'dtype';
  static readonly id = 'id';
  readonly id = 'id';
  static readonly instanceLabel = 'instanceLabel';
  readonly instanceLabel = 'instanceLabel';
  static readonly prixRemiseHt = 'prixRemiseHt';
  readonly prixRemiseHt = 'prixRemiseHt';
  static readonly remise = 'remise';
  readonly remise = 'remise';
  static readonly updated = 'updated';
  readonly updated = 'updated';
  static readonly updatedBy = 'updatedBy';
  readonly updatedBy = 'updatedBy';
  static readonly vehiculeAutorise = 'vehiculeAutorise';
  readonly vehiculeAutorise = 'vehiculeAutorise';
  static readonly versionNum = 'versionNum';
  readonly versionNum = 'versionNum';
  static fields() {
    return new ItemContratGen_();
  }
}

