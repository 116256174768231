/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type TypeDevisGen = 'PARTICULIER' | 'SOCIETE' | 'PATENTE' | 'ASSOCIATION' | 'ADMINISTRATION';

export const TypeDevisGen = {
        PARTICULIER: 'PARTICULIER' as TypeDevisGen,
        SOCIETE: 'SOCIETE' as TypeDevisGen,
        PATENTE: 'PATENTE' as TypeDevisGen,
        ASSOCIATION: 'ASSOCIATION' as TypeDevisGen,
        ADMINISTRATION: 'ADMINISTRATION' as TypeDevisGen
        };

export class TypeDevisGen_ {
    static readonly instanceLabel = 'instanceLabel';
    readonly instanceLabel = 'instanceLabel';
static fields() {
return new TypeDevisGen_();
}
}

