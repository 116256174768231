/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type TypeAvenantGen = 'CHANGEMENT_NOM' | 'MODIFICATION_TARIFAIRE' | 'PERTE_BADGE' | 'PLACE_PARKING' | 'CHANGEMENT_BOX';

export const TypeAvenantGen = {
        CHANGEMENT_NOM: 'CHANGEMENT_NOM' as TypeAvenantGen,
        MODIFICATION_TARIFAIRE: 'MODIFICATION_TARIFAIRE' as TypeAvenantGen,
        PERTE_BADGE: 'PERTE_BADGE' as TypeAvenantGen,
        PLACE_PARKING: 'PLACE_PARKING' as TypeAvenantGen,
        CHANGEMENT_BOX: 'CHANGEMENT_BOX' as TypeAvenantGen
        };

export class TypeAvenantGen_ {
    static readonly instanceLabel = 'instanceLabel';
    readonly instanceLabel = 'instanceLabel';
static fields() {
return new TypeAvenantGen_();
}
}

