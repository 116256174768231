/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface SrActiviteClientPojoGen { 
    appel?: number;
    clientId?: number;
    clientInstanceLabel?: string;
    commentaire?: string;
    conseillerId?: number;
    conseillerInstanceLabel?: string;
    created?: Date;
    date?: Date;
    entree?: number;
    id?: number;
    instanceLabel?: string;
    mail?: number;
    reglement?: number;
    siteId?: number;
    siteInstanceLabel?: string;
    sortie?: number;
    source?: string;
    visite?: number;
    web?: number;
}

export class SrActiviteClientPojoGen_ {
  static readonly appel = 'appel';
  readonly appel = 'appel';
  static readonly clientId = 'clientId';
  readonly clientId = 'clientId';
  static readonly clientInstanceLabel = 'clientInstanceLabel';
  readonly clientInstanceLabel = 'clientInstanceLabel';
  static readonly commentaire = 'commentaire';
  readonly commentaire = 'commentaire';
  static readonly conseillerId = 'conseillerId';
  readonly conseillerId = 'conseillerId';
  static readonly conseillerInstanceLabel = 'conseillerInstanceLabel';
  readonly conseillerInstanceLabel = 'conseillerInstanceLabel';
  static readonly created = 'created';
  readonly created = 'created';
  static readonly date = 'date';
  readonly date = 'date';
  static readonly entree = 'entree';
  readonly entree = 'entree';
  static readonly id = 'id';
  readonly id = 'id';
  static readonly instanceLabel = 'instanceLabel';
  readonly instanceLabel = 'instanceLabel';
  static readonly mail = 'mail';
  readonly mail = 'mail';
  static readonly reglement = 'reglement';
  readonly reglement = 'reglement';
  static readonly siteId = 'siteId';
  readonly siteId = 'siteId';
  static readonly siteInstanceLabel = 'siteInstanceLabel';
  readonly siteInstanceLabel = 'siteInstanceLabel';
  static readonly sortie = 'sortie';
  readonly sortie = 'sortie';
  static readonly source = 'source';
  readonly source = 'source';
  static readonly visite = 'visite';
  readonly visite = 'visite';
  static readonly web = 'web';
  readonly web = 'web';
  static fields() {
    return new SrActiviteClientPojoGen_();
  }
}

