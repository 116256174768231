import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router, RoutesRecognized } from '@angular/router';
import { NgbNavConfig, NgbTooltipConfig } from '@ng-bootstrap/ng-bootstrap';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { filter, pairwise } from 'rxjs/operators';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  constructor(private router: Router,
              private ngbTooltipConfig: NgbTooltipConfig,
              private ngbNavConfig: NgbNavConfig,
              private http: HttpClient
              /** private updates: SwUpdate // utile si l'application utilise les Service Workers */) {

    const headerDict = {
      'Accept': 'application/json',
      'show-error': 'false'
    };
    const requestOptions = {
      headers: new HttpHeaders(headerDict)
    };
  }

  ngOnInit() {
    this.ngbTooltipConfig.openDelay = 1000;
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0);
    });

    this.router.events
      .pipe(filter((e: any) => e instanceof RoutesRecognized),
        pairwise()
      ).subscribe((e: any) => {
      window.sessionStorage.setItem('previousUrl', this.router.url);
    });

    // utile si l'application utilise les Service Workers
    // this.updates.available.subscribe(event => {
    //   console.log('Nouvelle version de l\'application détectée');
    //   this.updates.activateUpdate().then(value => {
    //     console.log('Une nouvelle version de l\'application a été installée, ' +
    //       'la page va être rechargée afin de prendre en compte cette nouvelle version');
    //     window.location.reload();
    //   });
    // });
  }

}
