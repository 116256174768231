import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {AdminComponent} from './theme/layout/admin/admin.component';
import {AuthComponent} from './theme/layout/auth/auth.component';
import {NotFoundComponent} from './home/maintenance/not-found.component';
import {AuthGuard} from './guards/auth.guard';
import {MonCompteComponent} from './home/mon-compte/mon-compte.component';

const routes: Routes = [
  {
    path: '',
    component: AdminComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        redirectTo: '/dashboard/statistiques/exploitation',
        pathMatch: 'full'
      },
      {
        path: 'administration',
        loadChildren: () => import('./administration/administration.module').then(module => module.AdministrationModule)
      },
      {
        path: 'dashboard',
        loadChildren: () => import('./home/dashboard/dashboard.module').then(module => module.DashboardModule)
      },
      {
        path: 'mon-compte',
        component: MonCompteComponent
      },
      {
        path: 'box',
        loadChildren: () => import('./home/box/box.module').then(module => module.BoxModule)
      },
      {
        path: 'clients',
        loadChildren: () => import('./home/client/client.module').then(module => module.ClientModule)
      },
      {
        path: 'activites-client',
        loadChildren: () => import('./home/activiteclient/activiteclient.module').then(module => module.ActiviteclientModule)
      },
      {
        path: 'devis',
        loadChildren: () => import('./home/devislocation/devis-location.module').then(module => module.DevisLocationModule)
      },
      {
        path: 'contrats',
        loadChildren: () => import('./home/contrat-location/contrat-location.module').then(module => module.ContratLocationModule)
      },
      {
        path: 'avenants',
        loadChildren: () => import('./home/avenant/avenant.module').then(module => module.AvenantModule)
      },
      {
        path: 'factures',
        loadChildren: () => import('./home/facture/facture.module').then(module => module.FactureModule)
      },
      {
        path: 'reservations',
        loadChildren: () => import('./home/reservation/reservation.module').then(module => module.ReservationModule)
      },
      {
        path: 'relances-devis',
        loadChildren: () => import('./home/relance-devis/relance-devis.module').then(module => module.RelanceDevisModule)
      },
    ]
  },
  {
    path: 'auth',
    component: AuthComponent,
    children: [
      {
        path: '',
        loadChildren: () => import('./authentication/authentication.module').then(module => module.AuthenticationModule)
      }
    ]
  },
  {
    path: '**',
    component: NotFoundComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
