/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type TypeBoxGen = 'BOX' | 'DOCK' | 'CONTENEUR' | 'PARKING' | 'EMPLACEMENT_CONTENEUR';

export const TypeBoxGen = {
        BOX: 'BOX' as TypeBoxGen,
        DOCK: 'DOCK' as TypeBoxGen,
        CONTENEUR: 'CONTENEUR' as TypeBoxGen,
        PARKING: 'PARKING' as TypeBoxGen,
        EMPLACEMENT_CONTENEUR: 'EMPLACEMENT_CONTENEUR' as TypeBoxGen
        };

export class TypeBoxGen_ {
    static readonly instanceLabel = 'instanceLabel';
    readonly instanceLabel = 'instanceLabel';
static fields() {
return new TypeBoxGen_();
}
}

