/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type StatutClientGen = 'PROSPECT' | 'CLIENT';

export const StatutClientGen = {
        PROSPECT: 'PROSPECT' as StatutClientGen,
        CLIENT: 'CLIENT' as StatutClientGen
        };

export class StatutClientGen_ {
    static readonly instanceLabel = 'instanceLabel';
    readonly instanceLabel = 'instanceLabel';
static fields() {
return new StatutClientGen_();
}
}

