/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type PeriodePaiementGen = 'MENSUEL' | 'TRIMESTRIEL' | 'SEMESTRIEL' | 'ANNUEL';

export const PeriodePaiementGen = {
        MENSUEL: 'MENSUEL' as PeriodePaiementGen,
        TRIMESTRIEL: 'TRIMESTRIEL' as PeriodePaiementGen,
        SEMESTRIEL: 'SEMESTRIEL' as PeriodePaiementGen,
        ANNUEL: 'ANNUEL' as PeriodePaiementGen
        };

export class PeriodePaiementGen_ {
    static readonly instanceLabel = 'instanceLabel';
    readonly instanceLabel = 'instanceLabel';
static fields() {
return new PeriodePaiementGen_();
}
}

