import {Component, EventEmitter, Input, OnChanges, Output} from '@angular/core';
import {FormGroup} from '@angular/forms';
import {NgbActiveModal, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {AsCoreBaseDomain} from '../../models/ascore-base-domain';
import {checkFormForSubmit} from '../ascore-input/form-submit.directive';
import {MessageService} from '../../service/message.service';
import {AsCoreConfirmModalComponent} from '../ascore-confirm-modal/ascore-confirm-modal.component';
import {faTimes} from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'ascore-composition',
  templateUrl: './ascore-composition.component.html',
  styleUrls: ['./ascore-composition.component.scss']
})
export class AscoreCompositionComponent implements OnChanges {

  faTimes = faTimes;

  @Input()
  public form: FormGroup;

  @Input()
  titre: string;

  @Input()
  entity: AsCoreBaseDomain;

  @Input()
  showEnregistrer = true;

  @Input()
  readonly = false;

  @Input()
  moreActionTemplate: any;

  @Input()
  messageConfirmation: string;

  @Output()
  afterPatchEntityEvent = new EventEmitter<any>();
  
  hasBeenPatched = false;

  constructor(public modal: NgbActiveModal,
              private modalService: NgbModal,
              private messageService: MessageService) {
  }

  ngOnChanges(): void {
    if (this.entity && this.form && !this.hasBeenPatched) {
      this.hasBeenPatched = true;
      this.form.patchValue(this.entity);
      this.afterPatchEntityEvent.emit(this.entity);
    }
  }

  onSave(): void {
    if (checkFormForSubmit(this.form, this.messageService)) {
      // TODO faire l'appel au back avant la fermeture de la modal !
      if (this.messageConfirmation) {
        const modal = this.modalService.open(AsCoreConfirmModalComponent);
        modal.componentInstance.message = this.messageConfirmation;
        modal.result.then(() => {
            this.closeOnSave();
          },
          () => {
            // Do nothing
          });
      } else {
        this.closeOnSave();
      }
    }
  }

  closeOnSave(): void {
    this.modal.close(this.form.getRawValue());
  }
}
