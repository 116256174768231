/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface MontantParSurfaceDtoGen { 
    montantApresRemise?: number;
    remise?: number;
    surface?: number;
}

export class MontantParSurfaceDtoGen_ {
  static readonly montantApresRemise = 'montantApresRemise';
  readonly montantApresRemise = 'montantApresRemise';
  static readonly remise = 'remise';
  readonly remise = 'remise';
  static readonly surface = 'surface';
  readonly surface = 'surface';
  static fields() {
    return new MontantParSurfaceDtoGen_();
  }
}

