import {Component, OnInit, TemplateRef} from '@angular/core';
import {ToastService} from './ascore-toast.service';
import {MessageService} from '../service/message.service';
import {isNil} from 'lodash';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'ascore-toaster',
  templateUrl: './ascore-toaster.component.html',
  styleUrls: ['./ascore-toaster.component.scss']
})
export class AsCoreToasterComponent implements OnInit {

  constructor(public toastService: ToastService,
              private messageService: MessageService) {
  }

  ngOnInit(): void {
    this.messageService.erreurObservable
      .pipe(untilDestroyed(this))
      .subscribe(erreur => {
        if (erreur.message) {
          this.showDanger(erreur.message);
        }
      });
    this.messageService.warningObservable
      .pipe(untilDestroyed(this))
      .subscribe(warning => {
        if (warning.message) {
          this.showWarning(warning.message);
        }
      });
    this.messageService.successObservable
      .pipe(untilDestroyed(this))
      .subscribe(success => {
        if (success.message) {
          this.showSuccess(success.message);
        }
      });
  }

  showStandard(message: string): void {
    this.toastService.show(message);
  }

  showSuccess(message: string): void {
    this.toastService.show(message, {classname: 'bg-success text-light', delay: 5000});
  }

  showWarning(message: string): void {
    this.toastService.show(message, {classname: 'bg-warning text-light', delay: 10000});
  }

  showDanger(message: string): void {
    this.toastService.show(message, {classname: 'bg-danger text-light', delay: 10000});
  }

  isTemplate(toast): boolean {
    return toast.textOrTpl instanceof TemplateRef;
  }

  hasHeader(toast): boolean {
    return !isNil(toast.header);
  }
}
