import {Component, ViewChild} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {
  AsCoreColumn,
  AsCoreSearchComponent
} from '../../../../shared/ascore/component/ascore-search/ascore-search.component';
import {AsCoreFormControl} from '../../../../shared/ascore/component/ascore-input/AsCoreFormControl';
import {RoleGen, UtilisateurGen, UtilisateurGen_, UtilisateurServiceGen} from '../../../../shared/generated';

import {join} from 'lodash';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {UtilisateurCreateDetailComponent} from '../utilisateur-create-detail/utilisateur-create-detail.component';
import {UtilisateurCourantService} from "../../utilisateur-courant.service";

@Component({
  selector: 'app-utilisateur-search',
  templateUrl: './utilisateur-search.component.html',
  styleUrls: ['./utilisateur-search.component.scss']
})
export class UtilisateurSearchComponent {
  @ViewChild('utilisateurSearch') ascoreSearch: AsCoreSearchComponent<UtilisateurGen>;

  searchForm: FormGroup;

  columns: AsCoreColumn[] = [
    {header: 'Identifiant', fieldName: UtilisateurGen_.username, sortFieldName: UtilisateurGen_.username},
    {header: 'Nom', fieldName: UtilisateurGen_.nom, sortFieldName: UtilisateurGen_.nom},
    {header: 'Prénom', fieldName: UtilisateurGen_.prenom, sortFieldName: UtilisateurGen_.prenom},
    {header: 'Fonction', fieldName: UtilisateurGen_.fonction, sortFieldName: UtilisateurGen_.fonction},
    {header: 'Rôles', fieldName: join([UtilisateurGen_.listRole, 'instanceLabel'], '.')},
    {header: 'Actif', fieldName: UtilisateurGen_.actif, sortFieldName: UtilisateurGen_.actif},
  ];

  constructor(private fb: FormBuilder,
              private utilisateurCourantService: UtilisateurCourantService,
              public utilisateurService: UtilisateurServiceGen, private modalService: NgbModal) {
    this.createForm();
  }

  createForm(): void {
    this.searchForm = this.fb.group({
      recherche: AsCoreFormControl.init().label('Recherche'),
    });
  }


  openCustomCreate(event): void {
    const modal = this.modalService.open(UtilisateurCreateDetailComponent, {backdrop: 'static', size: 'xl'});

    modal.result.then((result) => {
        // Do nothing
        this.ascoreSearch.openEntity(result);
      },
      () => {
        // Do nothing
      });
  }

  isAddVisible() {
    return this.utilisateurCourantService.utilisateurCourantHasRole(RoleGen.ADMIN)
  }
}
