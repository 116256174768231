/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type ModeCommunicationGen = 'WEB' | 'MAIL' | 'TELEPHONE' | 'VISITE';

export const ModeCommunicationGen = {
        WEB: 'WEB' as ModeCommunicationGen,
        MAIL: 'MAIL' as ModeCommunicationGen,
        TELEPHONE: 'TELEPHONE' as ModeCommunicationGen,
        VISITE: 'VISITE' as ModeCommunicationGen
        };

export class ModeCommunicationGen_ {
    static readonly instanceLabel = 'instanceLabel';
    readonly instanceLabel = 'instanceLabel';
static fields() {
return new ModeCommunicationGen_();
}
}

