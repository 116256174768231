import {AsCoreSearchComponent} from './ascore-search.component';
import {UntilDestroy} from '@ngneat/until-destroy';
import {Component, Input} from '@angular/core';
import {PagedRessource} from '../../service/paged-ressources';
import {Observable} from 'rxjs';
import {WithFilterSearchHelper} from '../../service/api/with-filter-search.helper';

@UntilDestroy()
@Component({
  selector: 'ascore-search-by-filter',
  templateUrl: './ascore-search.component.html',
  styleUrls: ['./ascore-search.component.scss']
})
export class AsCoreSearchByFilterComponent<T, SEARCH = string, RESULT = T>
  extends AsCoreSearchComponent<T> {

  @Input()
  withFilterSearchHelper: WithFilterSearchHelper;

  protected callSearch(searchParams): Observable<PagedRessource<any>> {
    return this.withFilterSearchHelper.callSearch(this.construitPaginationForBackend());
  }

  //Override - permet l'export avec filtre
  construitSearchParams(): any {
    return this.withFilterSearchHelper.filterBuilder().build()
  }
}
