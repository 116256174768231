<div class="modal-header">
    <h6 class="modal-title">{{titre}}</h6>
    <button type="button" class="close customClose" aria-label="Close button" aria-describedby="modal-title"
            (click)="modal.dismiss('Cross click')">
        <fa-icon [icon]="faTimes" class="tw-text-white"></fa-icon>
    </button>
</div>
<div class="modal-body">

    <ng-content></ng-content>

</div>
<div class="modal-footer">
    <button type="button" class="btn btn-outline-secondary" (click)="modal.dismiss('cancel click')">Annuler</button>
    <button *ngIf="showEnregistrer && !readonly"
            type="button" ngbAutofocus class="btn btn-primary"
            (click)="onSave()">Enregistrer
    </button>
    <div *ngIf="moreActionTemplate">
        <ng-container
                *ngTemplateOutlet="moreActionTemplate;context:{entity: entity}">
        </ng-container>
    </div>
</div>
