/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type ModePaiementGen = 'ESPECE' | 'CHEQUE' | 'PRELEVEMENT_10' | 'PRELEVEMENT_25' | 'VIREMENT' | 'EPAY' | 'AREC';

export const ModePaiementGen = {
        ESPECE: 'ESPECE' as ModePaiementGen,
        CHEQUE: 'CHEQUE' as ModePaiementGen,
        PRELEVEMENT_10: 'PRELEVEMENT_10' as ModePaiementGen,
        PRELEVEMENT_25: 'PRELEVEMENT_25' as ModePaiementGen,
        VIREMENT: 'VIREMENT' as ModePaiementGen,
        EPAY: 'EPAY' as ModePaiementGen,
        AREC: 'AREC' as ModePaiementGen
        };

export class ModePaiementGen_ {
    static readonly instanceLabel = 'instanceLabel';
    readonly instanceLabel = 'instanceLabel';
static fields() {
return new ModePaiementGen_();
}
}

