/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { TypeBoxGen } from './type-box.gen.model';
import { DevisLocationGen } from './devis-location.gen.model';
import { PeriodePaiementGen } from './periode-paiement.gen.model';
import { SiteGen } from './site.gen.model';
import { TauxTgcGen } from './taux-tgc.gen.model';


export interface ItemDevisGen { 
    created: Date;
    createdBy: string;
    designation: string;
    devis: DevisLocationGen;
    dtype?: string;
    id?: number;
    instanceLabel: string;
    periodePaiement?: PeriodePaiementGen;
    prixUnitaireHt: number;
    quantite: number;
    remise: number;
    site?: SiteGen;
    superficie?: number;
    tauxTgc: TauxTgcGen;
    totalTtc: number;
    typeBox?: TypeBoxGen;
    updated?: Date;
    updatedBy?: string;
    versionNum?: number;
}

export class ItemDevisGen_ {
  static readonly created = 'created';
  readonly created = 'created';
  static readonly createdBy = 'createdBy';
  readonly createdBy = 'createdBy';
  static readonly designation = 'designation';
  readonly designation = 'designation';
  static readonly devis = 'devis';
  readonly devis = 'devis';
  static readonly dtype = 'dtype';
  readonly dtype = 'dtype';
  static readonly id = 'id';
  readonly id = 'id';
  static readonly instanceLabel = 'instanceLabel';
  readonly instanceLabel = 'instanceLabel';
  static readonly periodePaiement = 'periodePaiement';
  readonly periodePaiement = 'periodePaiement';
  static readonly prixUnitaireHt = 'prixUnitaireHt';
  readonly prixUnitaireHt = 'prixUnitaireHt';
  static readonly quantite = 'quantite';
  readonly quantite = 'quantite';
  static readonly remise = 'remise';
  readonly remise = 'remise';
  static readonly site = 'site';
  readonly site = 'site';
  static readonly superficie = 'superficie';
  readonly superficie = 'superficie';
  static readonly tauxTgc = 'tauxTgc';
  readonly tauxTgc = 'tauxTgc';
  static readonly totalTtc = 'totalTtc';
  readonly totalTtc = 'totalTtc';
  static readonly typeBox = 'typeBox';
  readonly typeBox = 'typeBox';
  static readonly updated = 'updated';
  readonly updated = 'updated';
  static readonly updatedBy = 'updatedBy';
  readonly updatedBy = 'updatedBy';
  static readonly versionNum = 'versionNum';
  readonly versionNum = 'versionNum';
  static fields() {
    return new ItemDevisGen_();
  }
}

