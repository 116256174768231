/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type StatutBoxGen = 'DISPONIBLE' | 'LOUE' | 'RESERVE' | 'MAINTENANCE';

export const StatutBoxGen = {
        DISPONIBLE: 'DISPONIBLE' as StatutBoxGen,
        LOUE: 'LOUE' as StatutBoxGen,
        RESERVE: 'RESERVE' as StatutBoxGen,
        MAINTENANCE: 'MAINTENANCE' as StatutBoxGen
        };

export class StatutBoxGen_ {
    static readonly instanceLabel = 'instanceLabel';
    readonly instanceLabel = 'instanceLabel';
static fields() {
return new StatutBoxGen_();
}
}

