/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface SrClientPojoGen { 
    adresseLigne1?: string;
    adresseLigne2?: string;
    boitePostale?: string;
    boxContentieux?: boolean;
    civiliteInstanceLabel?: string;
    clientFamilleInstanceLabel?: string;
    clientSousFamilleInstanceLabel?: string;
    codeClient?: string;
    codePostal?: string;
    commentaire?: string;
    created?: Date;
    createdBy?: string;
    email?: string;
    id?: number;
    instanceLabel?: string;
    nom?: string;
    pays?: string;
    prenom?: string;
    remise?: number;
    societe?: string;
    statutClientInstanceLabel?: string;
    tel1?: string;
    tel2?: string;
    updated?: Date;
    updatedBy?: string;
    versionNum?: number;
    ville?: string;
}

export class SrClientPojoGen_ {
  static readonly adresseLigne1 = 'adresseLigne1';
  readonly adresseLigne1 = 'adresseLigne1';
  static readonly adresseLigne2 = 'adresseLigne2';
  readonly adresseLigne2 = 'adresseLigne2';
  static readonly boitePostale = 'boitePostale';
  readonly boitePostale = 'boitePostale';
  static readonly boxContentieux = 'boxContentieux';
  readonly boxContentieux = 'boxContentieux';
  static readonly civiliteInstanceLabel = 'civiliteInstanceLabel';
  readonly civiliteInstanceLabel = 'civiliteInstanceLabel';
  static readonly clientFamilleInstanceLabel = 'clientFamilleInstanceLabel';
  readonly clientFamilleInstanceLabel = 'clientFamilleInstanceLabel';
  static readonly clientSousFamilleInstanceLabel = 'clientSousFamilleInstanceLabel';
  readonly clientSousFamilleInstanceLabel = 'clientSousFamilleInstanceLabel';
  static readonly codeClient = 'codeClient';
  readonly codeClient = 'codeClient';
  static readonly codePostal = 'codePostal';
  readonly codePostal = 'codePostal';
  static readonly commentaire = 'commentaire';
  readonly commentaire = 'commentaire';
  static readonly created = 'created';
  readonly created = 'created';
  static readonly createdBy = 'createdBy';
  readonly createdBy = 'createdBy';
  static readonly email = 'email';
  readonly email = 'email';
  static readonly id = 'id';
  readonly id = 'id';
  static readonly instanceLabel = 'instanceLabel';
  readonly instanceLabel = 'instanceLabel';
  static readonly nom = 'nom';
  readonly nom = 'nom';
  static readonly pays = 'pays';
  readonly pays = 'pays';
  static readonly prenom = 'prenom';
  readonly prenom = 'prenom';
  static readonly remise = 'remise';
  readonly remise = 'remise';
  static readonly societe = 'societe';
  readonly societe = 'societe';
  static readonly statutClientInstanceLabel = 'statutClientInstanceLabel';
  readonly statutClientInstanceLabel = 'statutClientInstanceLabel';
  static readonly tel1 = 'tel1';
  readonly tel1 = 'tel1';
  static readonly tel2 = 'tel2';
  readonly tel2 = 'tel2';
  static readonly updated = 'updated';
  readonly updated = 'updated';
  static readonly updatedBy = 'updatedBy';
  readonly updatedBy = 'updatedBy';
  static readonly versionNum = 'versionNum';
  readonly versionNum = 'versionNum';
  static readonly ville = 'ville';
  readonly ville = 'ville';
  static fields() {
    return new SrClientPojoGen_();
  }
}

