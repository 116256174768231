import {Directive, ElementRef, Input} from '@angular/core';
import {fromEvent, Subscription} from 'rxjs';
import {FormGroup} from '@angular/forms';
import {MessageService} from '../../service/message.service';
import {AsCoreFormControl} from './AsCoreFormControl';
import {joinListStringNotBlank} from '../../utils/string-util';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';

@UntilDestroy()
@Directive({
  selector: 'form'
})
export class FormSubmitDirective {

  @Input()
  formGroup: FormGroup;

  private submit: Subscription;

  constructor(private host: ElementRef<HTMLFormElement>, private messageService: MessageService) {
    this.submit = fromEvent(this.host.nativeElement, 'submit')
      .pipe(untilDestroyed(this))
      .subscribe((submitEvent) => {
        checkFormForSubmit(this.formGroup, this.messageService);
      })
  }

}

export function checkFormForSubmit(form: FormGroup, messageService: MessageService): boolean {

  if (form.disabled) {
    return true;
  }
  const listLabelInvalid = [];

  Object.keys(form.controls).forEach(key => {

    const control = form.controls[key];
    // On ne veut pas emettre d'evenement sinon on déclenche les actions dans ascore-input (qui seront levées sur le markAsDirty)
    control.updateValueAndValidity({onlySelf: false, emitEvent: false});
    if (control.invalid) {
      if (control instanceof AsCoreFormControl) {
        listLabelInvalid.push(control.getLabel());
      }
      control.markAsDirty();
    }
  });

  if (form.invalid) {
    let message = 'Des informations obligatoires sont incorrectes ou manquantes';
    if (listLabelInvalid.length < 10 && listLabelInvalid.length > 0) {
      message += (' : ' + joinListStringNotBlank(', ', listLabelInvalid));
    }
    messageService.showError(message);
  }

  return form.valid || form.disabled;
}
