/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ModePaiementGen } from './mode-paiement.gen.model';
import { ClientSousFamilleGen } from './client-sous-famille.gen.model';
import { StatutClientGen } from './statut-client.gen.model';
import { ClientFamilleGen } from './client-famille.gen.model';
import { PeriodePaiementGen } from './periode-paiement.gen.model';
import { CiviliteGen } from './civilite.gen.model';


export interface ClientGen { 
    adresseLigne1?: string;
    adresseLigne2?: string;
    boitePostale?: string;
    civilite?: CiviliteGen;
    clientFamille?: ClientFamilleGen;
    clientSousFamille?: ClientSousFamilleGen;
    codeClient?: string;
    codePostal?: string;
    codeTva?: string;
    codeVentilCompta?: string;
    commentaire?: string;
    created: Date;
    createdBy: string;
    dateNaissance?: Date;
    dtype?: string;
    email?: string;
    id?: number;
    instanceLabel: string;
    lieuDeNaissance?: string;
    modePaiement?: ModePaiementGen;
    nom?: string;
    pays?: string;
    periodePaiement?: PeriodePaiementGen;
    prefereRecevoirDocumentParMail?: boolean;
    prenom?: string;
    remise?: number;
    societe?: string;
    soumisTva?: boolean;
    statut?: StatutClientGen;
    tel1?: string;
    tel2?: string;
    updated?: Date;
    updatedBy?: string;
    versionNum?: number;
    ville?: string;
}

export class ClientGen_ {
  static readonly adresseLigne1 = 'adresseLigne1';
  readonly adresseLigne1 = 'adresseLigne1';
  static readonly adresseLigne2 = 'adresseLigne2';
  readonly adresseLigne2 = 'adresseLigne2';
  static readonly boitePostale = 'boitePostale';
  readonly boitePostale = 'boitePostale';
  static readonly civilite = 'civilite';
  readonly civilite = 'civilite';
  static readonly clientFamille = 'clientFamille';
  readonly clientFamille = 'clientFamille';
  static readonly clientSousFamille = 'clientSousFamille';
  readonly clientSousFamille = 'clientSousFamille';
  static readonly codeClient = 'codeClient';
  readonly codeClient = 'codeClient';
  static readonly codePostal = 'codePostal';
  readonly codePostal = 'codePostal';
  static readonly codeTva = 'codeTva';
  readonly codeTva = 'codeTva';
  static readonly codeVentilCompta = 'codeVentilCompta';
  readonly codeVentilCompta = 'codeVentilCompta';
  static readonly commentaire = 'commentaire';
  readonly commentaire = 'commentaire';
  static readonly created = 'created';
  readonly created = 'created';
  static readonly createdBy = 'createdBy';
  readonly createdBy = 'createdBy';
  static readonly dateNaissance = 'dateNaissance';
  readonly dateNaissance = 'dateNaissance';
  static readonly dtype = 'dtype';
  readonly dtype = 'dtype';
  static readonly email = 'email';
  readonly email = 'email';
  static readonly id = 'id';
  readonly id = 'id';
  static readonly instanceLabel = 'instanceLabel';
  readonly instanceLabel = 'instanceLabel';
  static readonly lieuDeNaissance = 'lieuDeNaissance';
  readonly lieuDeNaissance = 'lieuDeNaissance';
  static readonly modePaiement = 'modePaiement';
  readonly modePaiement = 'modePaiement';
  static readonly nom = 'nom';
  readonly nom = 'nom';
  static readonly pays = 'pays';
  readonly pays = 'pays';
  static readonly periodePaiement = 'periodePaiement';
  readonly periodePaiement = 'periodePaiement';
  static readonly prefereRecevoirDocumentParMail = 'prefereRecevoirDocumentParMail';
  readonly prefereRecevoirDocumentParMail = 'prefereRecevoirDocumentParMail';
  static readonly prenom = 'prenom';
  readonly prenom = 'prenom';
  static readonly remise = 'remise';
  readonly remise = 'remise';
  static readonly societe = 'societe';
  readonly societe = 'societe';
  static readonly soumisTva = 'soumisTva';
  readonly soumisTva = 'soumisTva';
  static readonly statut = 'statut';
  readonly statut = 'statut';
  static readonly tel1 = 'tel1';
  readonly tel1 = 'tel1';
  static readonly tel2 = 'tel2';
  readonly tel2 = 'tel2';
  static readonly updated = 'updated';
  readonly updated = 'updated';
  static readonly updatedBy = 'updatedBy';
  readonly updatedBy = 'updatedBy';
  static readonly versionNum = 'versionNum';
  readonly versionNum = 'versionNum';
  static readonly ville = 'ville';
  readonly ville = 'ville';
  static fields() {
    return new ClientGen_();
  }
}

