import {isBlank} from '../../utils/string-util';
import {filterDoubleQuote, FilterOperator} from './ascore-search.component';
import {isEmpty, isNil, trim} from 'lodash';
import {FormGroup} from '@angular/forms';

export type LogicalOperator = 'and' | 'or';

/**
 * Constructeur de filtres ET / OU parenthésés, imbriqués
 */
export class Filter {

  private constructor() {
  }

  logicalOperator: LogicalOperator;
  filter1: Filter;
  filter2: Filter;
  expr: string;

  static empty(): Filter {
    return Filter.of('');
  }

  static of(expr: string): Filter {
    const result: Filter = new Filter();
    result.expr = isBlank(expr) ? '' : trim(expr);
    return result;
  }

  static withParentheseOrEmpty(text): string {
    return isBlank(text) ? '' : '(' + text + ')';
  }

  and(filter: Filter): Filter {
    return this.condition(this, filter, 'and');
  }

  or(filter: Filter): Filter {
    return this.condition(this, filter, 'or');
  }

  condition(filter1: Filter, filter2: Filter, logicalOperator: LogicalOperator): Filter {
    const result: Filter = new Filter();
    result.filter1 = filter1;
    result.filter2 = filter2;
    result.logicalOperator = logicalOperator;
    return result;
  }

  /**
   * retourne le filtre (ou "" si aucun filtre particulier)
   */
  build(): string {
    if (this.logicalOperator === 'and' || this.logicalOperator === 'or') {
      const filter1asText = this.filter1.build();
      const filter2asText = this.filter2.build();
      if (isBlank(filter2asText)) {
        return filter1asText;
      }
      if (isBlank(filter1asText)) {
        return filter2asText;
      }
      return [filter1asText, filter2asText]
        .map(f => Filter.withParentheseOrEmpty(f))
        .join(' ' + this.logicalOperator + ' ');
    }

    return this.expr;
  }
}

export function filterText(textValue: string, textFieldName: string, operator: FilterOperator, bddExpr = textFieldName): Filter {
  return isBlank(textValue) ? Filter.empty() : Filter.of(bddExpr + ' ' + operator + ' ' + filterDoubleQuote(textValue));
}

export function filterTextFromForm(searchForm: FormGroup, textFieldName: string,
                                   operator: FilterOperator, bddExpr = textFieldName): Filter {
  const textValue = searchForm.get(textFieldName).value;
  return filterText(textValue, textFieldName, operator, bddExpr);
}

//attention l'ordre des paramètres est différent des autres filtres (qui sont resté en l'état pour des raisons historiques)
export function filterNumberFromForm(searchForm: FormGroup, bddExpr: string,
                                     operator: FilterOperator, textFieldName): Filter {
  const textValue = searchForm.get(textFieldName).value;
  return filterText(textValue, textFieldName, operator, bddExpr);
}

export function filterEnumEqualsFromForm(searchForm, enumFieldName: string, bddExpr = enumFieldName): Filter {
  if (!searchForm.get(enumFieldName)) {
    alert(enumFieldName)
  }
  const enumValue = searchForm.get(enumFieldName).value;
  return filterEntityEquals(enumValue, enumFieldName, bddExpr);
}


export function filterEntityEqualsFromForm(searchForm, entityFieldName: string, bddExpr = entityFieldName + 'Id'): Filter {
  const value = searchForm.get(entityFieldName).value;
  return filterEntityEquals(value, entityFieldName, bddExpr);
}

export function filterEntityEquals(entity, entityFieldName: string, bddExpr = entityFieldName + 'Id'): Filter {
  return isNil(entity?.id) ? Filter.empty() : Filter.of(bddExpr + ' eq ' + filterDoubleQuote(entity.id));
}

export function filterEntityIn(searchForm, entityFieldName: string, bddExpr = entityFieldName + 'Id'): Filter {
  const arrayOfValue: Array<any> = searchForm.get(entityFieldName).value;
  if (isEmpty(arrayOfValue)) {
    return Filter.empty();
  }
  const expr = arrayOfValue.map(value => bddExpr + ' eq ' + filterDoubleQuote(value.id)).join(' or ');
  return Filter.of(expr);
}

export function filterDateFromForm(searchForm, bddExpr: string, filterOperator: FilterOperator, dateFieldName: string): Filter {
  const value = searchForm.get(dateFieldName).value;
  return filterDate(value, bddExpr, filterOperator);
}

export function filterDate(value, bddExpr: string, filterOperator: FilterOperator): Filter {
  return isNil(value) ? Filter.empty() : Filter.of(bddExpr + ' ' + filterOperator + ' ' + filterDoubleQuote(value));
}

export function filterBooleanFromForm(searchForm, bddExpr: string): Filter {
  const value = searchForm.get(bddExpr).value;
  return isNil(value) ? Filter.empty() : Filter.of(bddExpr + ' eq ' + value);
}
