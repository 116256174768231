/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type PositionPorteGen = 'HAUT' | 'BAS' | 'GAUCHE' | 'DROITE';

export const PositionPorteGen = {
        HAUT: 'HAUT' as PositionPorteGen,
        BAS: 'BAS' as PositionPorteGen,
        GAUCHE: 'GAUCHE' as PositionPorteGen,
        DROITE: 'DROITE' as PositionPorteGen
        };

export class PositionPorteGen_ {
    static readonly instanceLabel = 'instanceLabel';
    readonly instanceLabel = 'instanceLabel';
static fields() {
return new PositionPorteGen_();
}
}

