/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type MoisGen = 'JANVIER' | 'FEVRIER' | 'MARS' | 'AVRIL' | 'MAI' | 'JUIN' | 'JUILLET' | 'AOUT' | 'SEPTEMBRE' | 'OCTOBRE' | 'NOVEMBER' | 'DECEMBRE';

export const MoisGen = {
        JANVIER: 'JANVIER' as MoisGen,
        FEVRIER: 'FEVRIER' as MoisGen,
        MARS: 'MARS' as MoisGen,
        AVRIL: 'AVRIL' as MoisGen,
        MAI: 'MAI' as MoisGen,
        JUIN: 'JUIN' as MoisGen,
        JUILLET: 'JUILLET' as MoisGen,
        AOUT: 'AOUT' as MoisGen,
        SEPTEMBRE: 'SEPTEMBRE' as MoisGen,
        OCTOBRE: 'OCTOBRE' as MoisGen,
        NOVEMBER: 'NOVEMBER' as MoisGen,
        DECEMBRE: 'DECEMBRE' as MoisGen
        };

export class MoisGen_ {
    static readonly instanceLabel = 'instanceLabel';
    readonly instanceLabel = 'instanceLabel';
static fields() {
return new MoisGen_();
}
}

