/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { MontantParSurfaceDtoGen } from './montant-par-surface-dto.gen.model';


export interface PrixMoyenParSurfaceDtoGen { 
    listPrixParSurface?: Array<MontantParSurfaceDtoGen>;
}

export class PrixMoyenParSurfaceDtoGen_ {
  static readonly listPrixParSurface = 'listPrixParSurface';
  readonly listPrixParSurface = 'listPrixParSurface';
  static fields() {
    return new PrixMoyenParSurfaceDtoGen_();
  }
}

