/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { AvenantGen } from './avenant.gen.model';
import { ArticleGen } from './article.gen.model';


export interface ItemAvenantGen { 
    article: ArticleGen;
    avenant: AvenantGen;
    created: Date;
    createdBy: string;
    dtype?: string;
    id?: number;
    instanceLabel: string;
    numero?: string;
    prixUnitaire: number;
    quantite: number;
    remise: number;
    updated?: Date;
    updatedBy?: string;
    versionNum?: number;
}

export class ItemAvenantGen_ {
  static readonly article = 'article';
  readonly article = 'article';
  static readonly avenant = 'avenant';
  readonly avenant = 'avenant';
  static readonly created = 'created';
  readonly created = 'created';
  static readonly createdBy = 'createdBy';
  readonly createdBy = 'createdBy';
  static readonly dtype = 'dtype';
  readonly dtype = 'dtype';
  static readonly id = 'id';
  readonly id = 'id';
  static readonly instanceLabel = 'instanceLabel';
  readonly instanceLabel = 'instanceLabel';
  static readonly numero = 'numero';
  readonly numero = 'numero';
  static readonly prixUnitaire = 'prixUnitaire';
  readonly prixUnitaire = 'prixUnitaire';
  static readonly quantite = 'quantite';
  readonly quantite = 'quantite';
  static readonly remise = 'remise';
  readonly remise = 'remise';
  static readonly updated = 'updated';
  readonly updated = 'updated';
  static readonly updatedBy = 'updatedBy';
  readonly updatedBy = 'updatedBy';
  static readonly versionNum = 'versionNum';
  readonly versionNum = 'versionNum';
  static fields() {
    return new ItemAvenantGen_();
  }
}

