/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ModePaiementGen } from './mode-paiement.gen.model';


export interface AcquittementFactureDtoGen { 
    commentaire?: string;
    modePaiement?: ModePaiementGen;
}

export class AcquittementFactureDtoGen_ {
  static readonly commentaire = 'commentaire';
  readonly commentaire = 'commentaire';
  static readonly modePaiement = 'modePaiement';
  readonly modePaiement = 'modePaiement';
  static fields() {
    return new AcquittementFactureDtoGen_();
  }
}

