import { NgModule, ModuleWithProviders, SkipSelf, Optional } from '@angular/core';
import { AsCoreConfiguration } from './configuration';
import { HttpClient } from '@angular/common/http';

import { ActiviteClientServiceGen } from './api/activite-client.gen.service';
import { AppConfigServiceGen } from './api/app-config.gen.service';
import { ArticleServiceGen } from './api/article.gen.service';
import { AuditServiceGen } from './api/audit.gen.service';
import { AuditAuthorServiceGen } from './api/audit-author.gen.service';
import { AvenantServiceGen } from './api/avenant.gen.service';
import { BoxServiceGen } from './api/box.gen.service';
import { CiviliteServiceGen } from './api/civilite.gen.service';
import { ClientServiceGen } from './api/client.gen.service';
import { ClientFamilleServiceGen } from './api/client-famille.gen.service';
import { ClientSousFamilleServiceGen } from './api/client-sous-famille.gen.service';
import { ContratLocationServiceGen } from './api/contrat-location.gen.service';
import { DevisLocationServiceGen } from './api/devis-location.gen.service';
import { DocumentServiceGen } from './api/document.gen.service';
import { EquipementServiceGen } from './api/equipement.gen.service';
import { EtatDevisServiceGen } from './api/etat-devis.gen.service';
import { EtatFactureServiceGen } from './api/etat-facture.gen.service';
import { FactureServiceGen } from './api/facture.gen.service';
import { ItemAvenantServiceGen } from './api/item-avenant.gen.service';
import { ItemContratServiceGen } from './api/item-contrat.gen.service';
import { ItemDevisServiceGen } from './api/item-devis.gen.service';
import { ItemFactureServiceGen } from './api/item-facture.gen.service';
import { ModeCommunicationServiceGen } from './api/mode-communication.gen.service';
import { ModePaiementServiceGen } from './api/mode-paiement.gen.service';
import { MoisServiceGen } from './api/mois.gen.service';
import { MotifDevisServiceGen } from './api/motif-devis.gen.service';
import { NiveauServiceGen } from './api/niveau.gen.service';
import { OuiNonServiceGen } from './api/oui-non.gen.service';
import { PeriodePaiementServiceGen } from './api/periode-paiement.gen.service';
import { PositionPorteServiceGen } from './api/position-porte.gen.service';
import { RelanceDevisServiceGen } from './api/relance-devis.gen.service';
import { ReservationServiceGen } from './api/reservation.gen.service';
import { RoleServiceGen } from './api/role.gen.service';
import { SciServiceGen } from './api/sci.gen.service';
import { SiteServiceGen } from './api/site.gen.service';
import { StatistiqueServiceGen } from './api/statistique.gen.service';
import { StatutBoxServiceGen } from './api/statut-box.gen.service';
import { StatutClientServiceGen } from './api/statut-client.gen.service';
import { TauxTgcServiceGen } from './api/taux-tgc.gen.service';
import { TypeAvenantServiceGen } from './api/type-avenant.gen.service';
import { TypeBoxServiceGen } from './api/type-box.gen.service';
import { TypeDevisServiceGen } from './api/type-devis.gen.service';
import { TypeDocumentServiceGen } from './api/type-document.gen.service';
import { UtilisateurServiceGen } from './api/utilisateur.gen.service';
import { VueBoxServiceGen } from './api/vue-box.gen.service';

@NgModule({
  imports:      [],
  declarations: [],
  exports:      [],
  providers: []
})
export class AsCoreApiModule {
    public static forRoot(configurationFactory: () => AsCoreConfiguration): ModuleWithProviders<AsCoreApiModule> {
        return {
            ngModule: AsCoreApiModule,
            providers: [ { provide: AsCoreConfiguration, useFactory: configurationFactory } ]
        };
    }

    constructor( @Optional() @SkipSelf() parentModule: AsCoreApiModule,
                 @Optional() http: HttpClient) {
        if (parentModule) {
            throw new Error('AsCoreApiModule is already loaded. Import in your base AppModule only.');
        }
        if (!http) {
            throw new Error('You need to import the HttpClientModule in your AppModule! \n' +
            'See also https://github.com/angular/angular/issues/20575');
        }
    }
}
