/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { SciGen } from './sci.gen.model';
import { TypeBoxGen } from './type-box.gen.model';
import { SiteGen } from './site.gen.model';
import { TauxTgcGen } from './taux-tgc.gen.model';


export interface ArticleGen { 
    code: string;
    created: Date;
    createdBy: string;
    dtype?: string;
    id?: number;
    instanceLabel: string;
    libelle?: string;
    produit?: boolean;
    sci?: SciGen;
    site?: SiteGen;
    tauxTgc: TauxTgcGen;
    typeBox?: TypeBoxGen;
    updated?: Date;
    updatedBy?: string;
    versionNum?: number;
}

export class ArticleGen_ {
  static readonly code = 'code';
  readonly code = 'code';
  static readonly created = 'created';
  readonly created = 'created';
  static readonly createdBy = 'createdBy';
  readonly createdBy = 'createdBy';
  static readonly dtype = 'dtype';
  readonly dtype = 'dtype';
  static readonly id = 'id';
  readonly id = 'id';
  static readonly instanceLabel = 'instanceLabel';
  readonly instanceLabel = 'instanceLabel';
  static readonly libelle = 'libelle';
  readonly libelle = 'libelle';
  static readonly produit = 'produit';
  readonly produit = 'produit';
  static readonly sci = 'sci';
  readonly sci = 'sci';
  static readonly site = 'site';
  readonly site = 'site';
  static readonly tauxTgc = 'tauxTgc';
  readonly tauxTgc = 'tauxTgc';
  static readonly typeBox = 'typeBox';
  readonly typeBox = 'typeBox';
  static readonly updated = 'updated';
  readonly updated = 'updated';
  static readonly updatedBy = 'updatedBy';
  readonly updatedBy = 'updatedBy';
  static readonly versionNum = 'versionNum';
  readonly versionNum = 'versionNum';
  static fields() {
    return new ArticleGen_();
  }
}

