/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { DocumentGen } from './document.gen.model';


export interface FinContratDtoGen { 
    commentaire?: string;
    commentaireSansRestriction?: string;
    contratId?: number;
    dateResiliation?: Date;
    listDocument?: Set<DocumentGen>;
    nbBadgeRendu?: number;
    nbCadenasRendu?: number;
    nbCleRendu?: number;
    numerosBadgeRendu?: string;
    representePar?: string;
    totalSansRestriction?: number;
}

export class FinContratDtoGen_ {
  static readonly commentaire = 'commentaire';
  readonly commentaire = 'commentaire';
  static readonly commentaireSansRestriction = 'commentaireSansRestriction';
  readonly commentaireSansRestriction = 'commentaireSansRestriction';
  static readonly contratId = 'contratId';
  readonly contratId = 'contratId';
  static readonly dateResiliation = 'dateResiliation';
  readonly dateResiliation = 'dateResiliation';
  static readonly listDocument = 'listDocument';
  readonly listDocument = 'listDocument';
  static readonly nbBadgeRendu = 'nbBadgeRendu';
  readonly nbBadgeRendu = 'nbBadgeRendu';
  static readonly nbCadenasRendu = 'nbCadenasRendu';
  readonly nbCadenasRendu = 'nbCadenasRendu';
  static readonly nbCleRendu = 'nbCleRendu';
  readonly nbCleRendu = 'nbCleRendu';
  static readonly numerosBadgeRendu = 'numerosBadgeRendu';
  readonly numerosBadgeRendu = 'numerosBadgeRendu';
  static readonly representePar = 'representePar';
  readonly representePar = 'representePar';
  static readonly totalSansRestriction = 'totalSansRestriction';
  readonly totalSansRestriction = 'totalSansRestriction';
  static fields() {
    return new FinContratDtoGen_();
  }
}

