<div class="container"
     *ngIf="this.flatConfig.layout === 'horizontal' && this.flatConfig.subLayout === 'horizontal-2'; else mainHeader">
  <ng-container *ngTemplateOutlet="mainHeader"></ng-container>
</div>
<ng-template #mainHeader>
  <a href="https://locabox.nc">
    <img class="logo collapse navbar-collapse logo-navbar-collapse" [style.display]="this.collapseStyle" alt="Logo"
         src="assets/images/logo-small.png">
  </a>
  <div class="m-header">
    <a *ngIf="this.windowWidth < 992" class="mobile-menu" id="mobile-collapse" (click)="navCollapse()"><span></span></a>
    <a [routerLink]="['/']" class="b-brand">
      <app-alert class="pr-4" type="danger"
                 *ngIf="location.origin.indexOf('-dev') != -1">
        Version de développement / recette
      </app-alert>
      <app-alert class="pr-4" type="danger"
                 *ngIf="location.origin.indexOf('localhost') != -1">Version
        locale
      </app-alert>
      <h1 style="padding-top: 8px; font-weight:bolder">LockBox</h1>
    </a>
  </div>
  <div class="collapse navbar-collapse" [style.display]="this.collapseStyle">
    <app-nav-right class="ml-auto"></app-nav-right>
  </div>
</ng-template>
