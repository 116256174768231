/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ModePaiementGen } from './mode-paiement.gen.model';
import { ClientGen } from './client.gen.model';
import { DocumentGen } from './document.gen.model';
import { EtatFactureGen } from './etat-facture.gen.model';
import { ItemFactureGen } from './item-facture.gen.model';


export interface FactureGen { 
    avoir?: boolean;
    client: ClientGen;
    commentaire?: string;
    created: Date;
    createdBy: string;
    dateEmission: Date;
    dateFacturation: Date;
    dtype?: string;
    etat: EtatFactureGen;
    exporteQuadratus?: boolean;
    facturePremierMoisPayeAvance?: boolean;
    id?: number;
    instanceLabel: string;
    listDocument?: Set<DocumentGen>;
    listItemFacture?: Set<ItemFactureGen>;
    modePaiement: ModePaiementGen;
    numero?: string;
    numeroFactureAvoir?: string;
    reference?: string;
    sansAdresseMail?: boolean;
    totalHt?: number;
    totalTgc?: number;
    totalTtc?: number;
    transmisCourrier?: boolean;
    transmisEmail?: boolean;
    updated?: Date;
    updatedBy?: string;
    versionNum?: number;
}

export class FactureGen_ {
  static readonly avoir = 'avoir';
  readonly avoir = 'avoir';
  static readonly client = 'client';
  readonly client = 'client';
  static readonly commentaire = 'commentaire';
  readonly commentaire = 'commentaire';
  static readonly created = 'created';
  readonly created = 'created';
  static readonly createdBy = 'createdBy';
  readonly createdBy = 'createdBy';
  static readonly dateEmission = 'dateEmission';
  readonly dateEmission = 'dateEmission';
  static readonly dateFacturation = 'dateFacturation';
  readonly dateFacturation = 'dateFacturation';
  static readonly dtype = 'dtype';
  readonly dtype = 'dtype';
  static readonly etat = 'etat';
  readonly etat = 'etat';
  static readonly exporteQuadratus = 'exporteQuadratus';
  readonly exporteQuadratus = 'exporteQuadratus';
  static readonly facturePremierMoisPayeAvance = 'facturePremierMoisPayeAvance';
  readonly facturePremierMoisPayeAvance = 'facturePremierMoisPayeAvance';
  static readonly id = 'id';
  readonly id = 'id';
  static readonly instanceLabel = 'instanceLabel';
  readonly instanceLabel = 'instanceLabel';
  static readonly listDocument = 'listDocument';
  readonly listDocument = 'listDocument';
  static readonly listItemFacture = 'listItemFacture';
  readonly listItemFacture = 'listItemFacture';
  static readonly modePaiement = 'modePaiement';
  readonly modePaiement = 'modePaiement';
  static readonly numero = 'numero';
  readonly numero = 'numero';
  static readonly numeroFactureAvoir = 'numeroFactureAvoir';
  readonly numeroFactureAvoir = 'numeroFactureAvoir';
  static readonly reference = 'reference';
  readonly reference = 'reference';
  static readonly sansAdresseMail = 'sansAdresseMail';
  readonly sansAdresseMail = 'sansAdresseMail';
  static readonly totalHt = 'totalHt';
  readonly totalHt = 'totalHt';
  static readonly totalTgc = 'totalTgc';
  readonly totalTgc = 'totalTgc';
  static readonly totalTtc = 'totalTtc';
  readonly totalTtc = 'totalTtc';
  static readonly transmisCourrier = 'transmisCourrier';
  readonly transmisCourrier = 'transmisCourrier';
  static readonly transmisEmail = 'transmisEmail';
  readonly transmisEmail = 'transmisEmail';
  static readonly updated = 'updated';
  readonly updated = 'updated';
  static readonly updatedBy = 'updatedBy';
  readonly updatedBy = 'updatedBy';
  static readonly versionNum = 'versionNum';
  readonly versionNum = 'versionNum';
  static fields() {
    return new FactureGen_();
  }
}

