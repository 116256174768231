import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  QueryList,
  SimpleChanges,
  TemplateRef,
  ViewChild,
  ViewChildren
} from '@angular/core';
import {FormGroup} from '@angular/forms';
import {PagedRessource} from '../../service/paged-ressources';
import {
  faChevronDown,
  faChevronUp,
  faDownload,
  faEraser,
  faPlus,
  faRetweet,
  faSearch,
  faTimes,
  faTrash
} from '@fortawesome/free-solid-svg-icons';
import {ActivatedRoute, Router} from '@angular/router';
import {
  SortableHeaderDirective,
  SortDirection,
  sortDirectionFromString,
  SortEvent
} from '../../directives/sortable-header.directive';
import {AsCoreBaseDomain} from '../../models/ascore-base-domain';
import {back, currentUrlWithoutParam, extractSearchParamsForRouter} from '../../utils/url-util';
import {SelectionChangeEvent, SelectTableEvent} from '../ascore-table/ascore-table.model';
import {AsCoreConfirmModalComponent} from '../ascore-confirm-modal/ascore-confirm-modal.component';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {MessageService} from '../../service/message.service';
import {isNil, isNumber} from 'lodash';
import {NgxSpinnerService} from 'ngx-spinner';
import {UntilDestroy} from '@ngneat/until-destroy';
import {isBlank} from '../../utils/string-util';
import {Observable, of} from 'rxjs';
import {Debounce} from '../../utils/debounce';
import {PageableGen} from '../../../generated';
import {WithDelete} from '../../service/api/with-delete';
import {WithSearch} from '../../service/api/with-search';
import {WithCount} from '../../service/api/with-count';
import {HttpClient, HttpResponse} from '@angular/common/http';
import {downloadDocument} from '../../utils/file-util';
import {WithExport} from '../../service/api/with-export';
import {environment} from '../../../../../environments/environment';

export type FilterOperator = 'eq' | 'gt' | 'ge' | 'lt' | 'le' | 'contains';   // cf Filter.g4

export function filterDoubleQuote(value: any): string {
  if (isNil(value)) {
    return '""';
  }
  if (isNumber(value)) {
    return '"' + value + '"';
  }
  const escapedText = value.replace(/"/g, '\\"');
  return '"' + escapedText + '"';
}

export const DEFAULT_PAGE_SIZE = 20;

export interface AsCoreColumn {
  header: string;
  fieldName?: string;
  sortFieldName?: string;
  tooltipFieldName?: string;
  template?;
  width?: string;
  id?: string;
}

@UntilDestroy()
@Component({
  selector: 'ascore-search',
  templateUrl: './ascore-search.component.html',
  styleUrls: ['./ascore-search.component.scss']
})
export class AsCoreSearchComponent<T> implements OnInit, OnChanges {


  @Input()
  title: string;

  @Input()
  customTitle: TemplateRef<any>;

  @Input()
  searchForm: FormGroup;

  @Input()
  hideSearchForm = false;

  @Input()
  withSearch: WithSearch<any, PagedRessource<any>>;

  @Input()
  searchActive = true;

  /**
   * A renseigner si on veut afficher le nombre total d'élémentsn, utile en mode slice
   * (le nombre est indépendant de la recherche (sinon utiliser mode paginé standard))
   */
  @Input()
  withCount: WithCount;
  totalCount?: number;

  @Input()
  withDelete: WithDelete;

  @Input()
  withExport: WithExport<any>;

  @Input()
  columns: AsCoreColumn[] = [];

  @Input()
  withSelection = false;

  @Input()
  startWithSelectionChecked = false;

  @Input()
  buttonAddVisible = true;

  @Input()
  typeDetail: TypeDetail = 'editable';

  @Input()
  customCreate = false;

  @Input()
  customOpen = false;

  @Input()
  header = true;

  @Input()
  addClass: string;

  @Input()
  customTableHeader: ElementRef;

  @Input()
  plusDeFiltreTemplate: TemplateRef<any>;

  @Input()
  resetSearchForm: (FormGroup) => void;

  @Input()
  customAction = false;

  @Input()
  customActionTemplate: TemplateRef<any>;

  @Input()
  closable = true;

  @Output()
  addEvent = new EventEmitter();

  @Output()
  openEvent = new EventEmitter();

  @Output()
  paginationSizeEvent = new EventEmitter();

  @Output()
  selectionChangeEvent = new EventEmitter<SelectionChangeEvent>();

  @Output()
  searchResultChange = new EventEmitter<T[]>();

  @ViewChildren(SortableHeaderDirective)
  headers: QueryList<SortableHeaderDirective>;

  @ViewChild('btnSupprimerTpl', {static: true}) btnSupprimerTpl: ElementRef;

  faPlus = faPlus;
  faSearch = faSearch;
  faTimes = faTimes;
  faEraser = faEraser;
  faDownload = faDownload;
  faChevronUp = faChevronUp;
  faChevronDown = faChevronDown;
  faTrash = faTrash;
  iconCount = faRetweet;

  pagedResources: PagedRessource<any> = new PagedRessource<AsCoreBaseDomain>();
  pagination: PageableGen = {} as PageableGen;
  additionalInfo: Map<string, any>;
  isPlusDeFiltreCollapsed = true;
  isAutoSearchActivated = false;

  /**
   * Permet de positionner les champs du formulaire correctement en fonction d'une Map nom champ => valeur (issues typiquement de l'url)
   */
  @Input()
  customSearchParamProcessors = (controls: { name, value }[]): Observable<any> => {
    controls.map(field => {
      const control = this.searchForm.get(field.name);
      if (control != null && !isBlank(field.value)) {
        control.setValue(field.value);
      }
    });
    return of(true);
  }

  constructor(private route: ActivatedRoute,
              private router: Router,
              private modalService: NgbModal,
              private messageService: MessageService,
              private http: HttpClient,
              private spinner: NgxSpinnerService) {
  }

  ngOnInit(): void {
    this.initSearchParamFromUrl().subscribe(() => {
      this.initPaginationFromUrl();
      this.search();
      this.isAutoSearchActivated = true;
    });
  }

  updateTotalCount(): void {
    this.withCount.count().subscribe(result => this.totalCount = result);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.columns && this.columns.map(it => it.header).indexOf('') < 0 && this.withDelete) {
      this.columns.push({header: '', fieldName: '', sortFieldName: '', template: this.btnSupprimerTpl});
    }
  }

  initSearchParamFromUrl(): Observable<any> {
    const listControlWithInitValue: { name: string, value: string | boolean }[] = Object.keys(this.searchForm.controls).filter(value => {
      return !isBlank(this.getParamFormUrl(value));
    }).map(value => {
      let valueInUrl: string | boolean = this.getParamFormUrl(value)
      if (valueInUrl == 'true') {
        valueInUrl = true
      }
      if (valueInUrl == 'false') {
        valueInUrl = false
      }
      return {name: value, value: valueInUrl};
    });
    if (listControlWithInitValue.length === 0) {
      return of(true);
    } else {
      return this.customSearchParamProcessors(listControlWithInitValue);
    }
  }

  getParamFormUrl(key: string): string {
    const queryParam = this.route.snapshot.queryParams[key];
    return !isBlank(queryParam) ? decodeURIComponent(queryParam) : null;
  }

  initPaginationFromUrl(): void {

    // valeurs par défaut
    this.pagination.page = 0;
    this.pagination.size = DEFAULT_PAGE_SIZE;
    this.pagination.sort = [];

    Object.keys(this.pagination).forEach((key, index) => {
        const valueFromUrl = this.getParamFormUrl(key);
        if (isBlank(valueFromUrl)) {
          return;
        }
        if (key === 'page') {
          this.pagination.page = parseInt(valueFromUrl, 10);
        } else if (key === 'sort') {
          const tokens = valueFromUrl.split(',');
          const propertyName = tokens[0].trim();
          const sortDirection: SortDirection = tokens.length >= 2 ? sortDirectionFromString(tokens[1]) : '';
          this.pagination.sort = this.construitSort(propertyName, sortDirection);
        } else if (key === 'size') {
          this.pagination.size = valueFromUrl ? parseInt(valueFromUrl, 10) : DEFAULT_PAGE_SIZE;
        }
      }
    );
  }

  onSort(event: SortEvent): void {
    this.headers.forEach(header => {
      if (header.asCoreSortable !== event.column) {
        header.direction = '';
      }
    });

    this.pagination.sort = this.construitSort(event.column, event.direction);
    this.search();
  }

  /** construit tableau de string, format pris en charge par les services générés TS */
  construitSort(propertyName: string, direction: SortDirection): Array<string> {
    if (isBlank(propertyName)) {
      return []; // pas de tri
    }
    return isBlank(direction) ? [propertyName] : [propertyName + ',' + direction];
  }

  @Debounce(environment.defaultDebounceTime)
  triggerFilter(): void {
    if (this.isAutoSearchActivated) {
      this.search();
    }
  }

  search(): void {
    if (!this.searchForm.valid) {
      return;
    }
    if (!this.searchActive) {
      return;
    }
    const searchParams = this.construitSearchParams();
    const spinnerTimer = setTimeout(() => this.spinner.show('ascore-search-spinner'), 350);

    this.callSearch(searchParams)
      .subscribe(result => {
        clearTimeout(spinnerTimer);
        this.spinner.hide('ascore-search-spinner');
        this.refreshUrlSearchParam();
        this.pagedResources = result;
        this.pagination.size = result.pageable.pageSize;
        this.pagination.page = result.pageable.pageNumber + 1;
        this.additionalInfo = result.additionalInfo;
        this.searchResultChange.emit(result.content);
      });
  }

  protected callSearch(searchParams): Observable<PagedRessource<any>> {
    return this.withSearch.search(searchParams, this.construitPaginationForBackend());
  }

  export(): void {
    if (!this.searchForm.valid) {
      return;
    }
    const searchParams = this.construitSearchParams();

    const options = {
      httpHeaderAccept: 'text/html'
    } as any;

    const exportObservable: Observable<HttpResponse<any>> = this.withExport._export(
      (searchParams as any),
      'response',
      false,
      options);
    return downloadDocument(exportObservable);
  }

  protected construitPaginationForBackend(): PageableGen {
    return {
      page: this.pagination.page && this.pagination.page > 0 ?
        this.pagination.page - 1 : 0,  // côté backend, numérotation à partir de 0
      size: this.pagination.size ? this.pagination.size : DEFAULT_PAGE_SIZE,
      sort: this.pagination.sort
    };
  }

  construitSearchParams(): any {
    return extractSearchParamsForRouter(this.searchForm.getRawValue());
  }

  resetSearch(): void {
    this.isAutoSearchActivated = false;
    if (!isNil(this.resetSearchForm)) {
      this.resetSearchForm(this.searchForm);
    } else {
      this.searchForm.reset();
    }
    this.isAutoSearchActivated = true;
    this.search();
  }

  add(): void {
    if (this.customCreate) {
      this.addEvent.emit();
    } else {
      this.router.navigateByUrl(currentUrlWithoutParam(this.router) + '/creation');
    }
  }

  open(event: SelectTableEvent): void {

    if (this.typeDetail === 'none') {
      return;
    }

    if (this.customOpen) {
      this.openEvent.emit(event);
    } else {
      this.openEntity(event.entity);
    }
  }

  openEntity(entity) {
    this.router.navigateByUrl(currentUrlWithoutParam(this.router) + '/' + entity.id);
  }

  private refreshUrlSearchParam(): Promise<boolean> {
    return this.router.navigate([], {
      relativeTo: this.route,
      queryParams: extractSearchParamsForRouter(this.searchForm.getRawValue()),
      replaceUrl: true
    });
  }

  close(): void {
    back(this.router);
  }

  delete(event: AsCoreBaseDomain): void {

    if (!this.withDelete) {
      return;
    }

    const modal = this.modalService.open(AsCoreConfirmModalComponent, {backdrop: 'static'});
    modal.componentInstance.message = 'Confirmez-vous la suppression de ' + event.instanceLabel + ' ?';

    modal.result.then(() => {
        // Suppression en base
        this.withDelete._delete(event.id).subscribe(() => {
          this.search();
          this.messageService.showSuccess(`Suppression de ${event.instanceLabel} effectuée`);
        });
      },
      () => {
        // Do nothing
      });
  }

  paginationSizeChanged(): void {
    this.paginationSizeEvent.emit(this.pagination.size);
  }

}


export type TypeDetail = 'none' | 'viewable' | 'editable';
