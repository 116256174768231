<ascore-detail
        [form]="form"
        domainName="Utilisateur"
        [withCrud]="service"
        [tabs]="tabs"
>
  <form [formGroup]="form">
    <div class="row">
      <div class="col-12 p-0 d-inline-flex flex-wrap">
        <div class="col-md-2">
          <input type="text"
                 [formControlName]="fields.username"
          >
        </div>
        <div class="col-md-2">
          <input type="password"
                 [formControlName]="fields.password"
          >
        </div>
        <div class="col-md-2">
          <input type="text"
                 asCoreUppercase
                 [formControlName]="fields.nom"
          >
        </div>
        <div class="col-md-2">
          <input type="text"
                 asCoreCapitalizeFully
                 [formControlName]="fields.prenom"
          >
        </div>
        <div class="col-md-4">
          <input type="text"
                 [formControlName]="fields.fonction"
          >
        </div>
        <div class="col-md-3">
          <input type="text"
                 asCoreLowercase
                 [formControlName]="fields.email"
          >
        </div>
        <div class="col-md-2 pt-3">
          <input type="checkbox"
                 [formControlName]="fields.actif"
          >
        </div>
      </div>
    </div>
  </form>
</ascore-detail>
