/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface SrItemContratPojoGen { 
    boxId?: number;
    boxInstanceLabel?: string;
    contratLocationId?: number;
    contratLocationInstanceLabel?: string;
    created?: Date;
    createdBy?: string;
    id?: number;
    instanceLabel?: string;
    prixRemiseHt?: number;
    remise?: number;
    updated?: Date;
    updatedBy?: string;
    versionNum?: number;
}

export class SrItemContratPojoGen_ {
  static readonly boxId = 'boxId';
  readonly boxId = 'boxId';
  static readonly boxInstanceLabel = 'boxInstanceLabel';
  readonly boxInstanceLabel = 'boxInstanceLabel';
  static readonly contratLocationId = 'contratLocationId';
  readonly contratLocationId = 'contratLocationId';
  static readonly contratLocationInstanceLabel = 'contratLocationInstanceLabel';
  readonly contratLocationInstanceLabel = 'contratLocationInstanceLabel';
  static readonly created = 'created';
  readonly created = 'created';
  static readonly createdBy = 'createdBy';
  readonly createdBy = 'createdBy';
  static readonly id = 'id';
  readonly id = 'id';
  static readonly instanceLabel = 'instanceLabel';
  readonly instanceLabel = 'instanceLabel';
  static readonly prixRemiseHt = 'prixRemiseHt';
  readonly prixRemiseHt = 'prixRemiseHt';
  static readonly remise = 'remise';
  readonly remise = 'remise';
  static readonly updated = 'updated';
  readonly updated = 'updated';
  static readonly updatedBy = 'updatedBy';
  readonly updatedBy = 'updatedBy';
  static readonly versionNum = 'versionNum';
  readonly versionNum = 'versionNum';
  static fields() {
    return new SrItemContratPojoGen_();
  }
}

