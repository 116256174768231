/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { NiveauGen } from './niveau.gen.model';


export interface SiteGen { 
    adresseLigne1: string;
    adresseLigne2?: string;
    codePostal: string;
    created: Date;
    createdBy: string;
    dtype?: string;
    email: string;
    id?: number;
    instanceLabel: string;
    listNiveau?: Set<NiveauGen>;
    nom: string;
    pays: string;
    tel1: string;
    tel2?: string;
    updated?: Date;
    updatedBy?: string;
    versionNum?: number;
    ville: string;
}

export class SiteGen_ {
  static readonly adresseLigne1 = 'adresseLigne1';
  readonly adresseLigne1 = 'adresseLigne1';
  static readonly adresseLigne2 = 'adresseLigne2';
  readonly adresseLigne2 = 'adresseLigne2';
  static readonly codePostal = 'codePostal';
  readonly codePostal = 'codePostal';
  static readonly created = 'created';
  readonly created = 'created';
  static readonly createdBy = 'createdBy';
  readonly createdBy = 'createdBy';
  static readonly dtype = 'dtype';
  readonly dtype = 'dtype';
  static readonly email = 'email';
  readonly email = 'email';
  static readonly id = 'id';
  readonly id = 'id';
  static readonly instanceLabel = 'instanceLabel';
  readonly instanceLabel = 'instanceLabel';
  static readonly listNiveau = 'listNiveau';
  readonly listNiveau = 'listNiveau';
  static readonly nom = 'nom';
  readonly nom = 'nom';
  static readonly pays = 'pays';
  readonly pays = 'pays';
  static readonly tel1 = 'tel1';
  readonly tel1 = 'tel1';
  static readonly tel2 = 'tel2';
  readonly tel2 = 'tel2';
  static readonly updated = 'updated';
  readonly updated = 'updated';
  static readonly updatedBy = 'updatedBy';
  readonly updatedBy = 'updatedBy';
  static readonly versionNum = 'versionNum';
  readonly versionNum = 'versionNum';
  static readonly ville = 'ville';
  readonly ville = 'ville';
  static fields() {
    return new SiteGen_();
  }
}

