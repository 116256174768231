/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface SrContratLocationPojoGen { 
    boxs?: string;
    boxsid?: string;
    clientId?: number;
    clientInstanceLabel?: string;
    conditionsParticulieres?: string;
    conseillerId?: number;
    conseillerInstanceLabel?: string;
    created?: Date;
    createdBy?: string;
    dateEmission?: Date;
    dateEntree?: Date;
    dateResiliation?: Date;
    devisLocationId?: number;
    devisLocationInstanceLabel?: string;
    etatId?: number;
    etatInstanceLabel?: string;
    id?: number;
    instanceLabel?: string;
    listNumBadges?: string;
    listSuperficie?: string;
    nbBadge?: number;
    nbCadenas?: number;
    nbCle?: number;
    numero?: string;
    prixRemiseHt?: number;
    remise?: number;
    resilie?: boolean;
    transmis?: boolean;
    updated?: Date;
    updatedBy?: string;
    versionNum?: number;
}

export class SrContratLocationPojoGen_ {
  static readonly boxs = 'boxs';
  readonly boxs = 'boxs';
  static readonly boxsid = 'boxsid';
  readonly boxsid = 'boxsid';
  static readonly clientId = 'clientId';
  readonly clientId = 'clientId';
  static readonly clientInstanceLabel = 'clientInstanceLabel';
  readonly clientInstanceLabel = 'clientInstanceLabel';
  static readonly conditionsParticulieres = 'conditionsParticulieres';
  readonly conditionsParticulieres = 'conditionsParticulieres';
  static readonly conseillerId = 'conseillerId';
  readonly conseillerId = 'conseillerId';
  static readonly conseillerInstanceLabel = 'conseillerInstanceLabel';
  readonly conseillerInstanceLabel = 'conseillerInstanceLabel';
  static readonly created = 'created';
  readonly created = 'created';
  static readonly createdBy = 'createdBy';
  readonly createdBy = 'createdBy';
  static readonly dateEmission = 'dateEmission';
  readonly dateEmission = 'dateEmission';
  static readonly dateEntree = 'dateEntree';
  readonly dateEntree = 'dateEntree';
  static readonly dateResiliation = 'dateResiliation';
  readonly dateResiliation = 'dateResiliation';
  static readonly devisLocationId = 'devisLocationId';
  readonly devisLocationId = 'devisLocationId';
  static readonly devisLocationInstanceLabel = 'devisLocationInstanceLabel';
  readonly devisLocationInstanceLabel = 'devisLocationInstanceLabel';
  static readonly etatId = 'etatId';
  readonly etatId = 'etatId';
  static readonly etatInstanceLabel = 'etatInstanceLabel';
  readonly etatInstanceLabel = 'etatInstanceLabel';
  static readonly id = 'id';
  readonly id = 'id';
  static readonly instanceLabel = 'instanceLabel';
  readonly instanceLabel = 'instanceLabel';
  static readonly listNumBadges = 'listNumBadges';
  readonly listNumBadges = 'listNumBadges';
  static readonly listSuperficie = 'listSuperficie';
  readonly listSuperficie = 'listSuperficie';
  static readonly nbBadge = 'nbBadge';
  readonly nbBadge = 'nbBadge';
  static readonly nbCadenas = 'nbCadenas';
  readonly nbCadenas = 'nbCadenas';
  static readonly nbCle = 'nbCle';
  readonly nbCle = 'nbCle';
  static readonly numero = 'numero';
  readonly numero = 'numero';
  static readonly prixRemiseHt = 'prixRemiseHt';
  readonly prixRemiseHt = 'prixRemiseHt';
  static readonly remise = 'remise';
  readonly remise = 'remise';
  static readonly resilie = 'resilie';
  readonly resilie = 'resilie';
  static readonly transmis = 'transmis';
  readonly transmis = 'transmis';
  static readonly updated = 'updated';
  readonly updated = 'updated';
  static readonly updatedBy = 'updatedBy';
  readonly updatedBy = 'updatedBy';
  static readonly versionNum = 'versionNum';
  readonly versionNum = 'versionNum';
  static fields() {
    return new SrContratLocationPojoGen_();
  }
}

